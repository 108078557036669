export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const GET_TASKS = 'GET_TASKS';
export const TASKS_ERROR = 'TASKS_ERROR';
export const CLEAR_TASKS = 'CLEAR_TASKS';

export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const PASSWORD_NOT_MATCH = 'PASSWORD_NOT_MATCH';
export const PASSWORD_SAME_AS_OLD = 'PASSWORD_SAME_AS_OLD';

export const DATA_GET = 'DATA_GET';
export const DATA_ERROR = 'DATA_ERROR';
export const DATA_CLEAR = 'DATA_CLEAR';

export const TIMETABLE_OPEN = 'TIMETABLE_OPEN';
export const TIMETABLE_CLOSE = 'TIMETABLE_CLOSE';
export const TIMETABLE_ERROR = 'TIMETABLE_ERROR';

export const SET_EDIT_ROW_KEY = 'SET_EDIT_ROW_KEY';
export const SET_IMAGE_LINK_KEY = 'SET_IMAGE_LINK_KEY';

export const PARAMS_GET = 'PARAMS_GET';
export const PARAMS_CLEAR = 'PARAMS_CLEAR';
export const PARAMS_ERROR = 'PARAMS_ERROR';
