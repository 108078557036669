import axios from 'axios';
import Button from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { backendHOST } from '../../client-config/client-config.js';
import def_avatar from '../../img/def_avatar.png';
import fiu_avatar_2 from '../../img/IT_Fiu2.png';
import lany_avatar_szoke from '../../img/IT_Lany1.png';
import lany_avatar_barna from '../../img/IT_Lany2.png';
import { MessagePopup } from '../forms/TaskManagement/MessagePopup.js';
import { TaskCreatePopup } from '../forms/TaskManagement/TaskCreatePopup.js';

const ProfileBox = ({ profile, tasks, user }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [displayProfileBox, toggleProfileBox] = useState(!isMobile);

  const [popupVisible, togglePopup] = useState(false);
  const [positionOf, setPopupPosition] = useState('');

  const getAvatar = (type) => {
    switch (type) {
      case 'F':
        return lany_avatar_barna;
      case 'M':
        return fiu_avatar_2;
      default:
        return lany_avatar_szoke;
    }
  };

  const hideInfo = (e) => {
    togglePopup(false);
  };

  const showInfo = (e) => {
    togglePopup(true);
    setPopupPosition(`#messageBox`);
  };
  const closeButtonOptions = {
    text: 'Bezár',
    onClick: hideInfo,
  };
  let taskVisible = false;
  if (tasks) {
    if (tasks.length > 0) taskVisible = true;
  } else {
    taskVisible = false;
  }
  let task = {
    createdByUser: 0,
    task_title: '',
    task_descr: '',
    task_object: '',
    task_key: '',
    task_recipient: '',
  };

  /* TASK Popoup definition*/
  const [taskCreateVisible, toggleTaskCreatePopup] = useState(false);
  const [positionOfCreateTask, setCreateTaksPopupPosition] = useState('');
  const hideTaskCreate = (e) => {
    toggleTaskCreatePopup(false);
  };

  const TaskCreate = (e) => {
    const cfg = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(backendHOST + '/api/taskManager/createTask', task, cfg)
      .then((data) => {
        task = {
          createdByUser: 0,
          task_title: '',
          task_descr: '',
          task_object: '',
          task_key: '',
          task_recipient: '',
        };
      })
      .catch((err) => {
        alert(err, 'Hiba a Task generálás során');
      });

    toggleTaskCreatePopup(false);
  };

  const showTaskCreate = (e) => {
    toggleTaskCreatePopup(true);
    setCreateTaksPopupPosition(`#taskCreateBox`);
  };
  const closeTaskCreateButtonOptions = {
    text: 'Bezár',
    onClick: hideTaskCreate,
  };

  const TaskCreateButtonOptions = {
    text: 'Létrehoz',
    onClick: TaskCreate,
  };

  /* END Task popup def*/
  if (!profile) {
    return (
      <div className='w3-card w3-round w3-white'>
        <div className='w3-container'>
          <h4 className='w3-center'>Még nincs profil</h4>
          <p className='w3-center'>
            <img
              src={def_avatar}
              className='w3-circle'
              style={{ height: '106px', width: '106px' }}
              alt='Avatar'
            />
          </p>
          <hr />

          <Link to='/create-profile'>
            <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
            {' Profil létrehozása'}
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className='w3-card w3-round w3-white'>
          <div className='w3-container'>
            <div className='w3-row'>
              <div className='w3-col m11 s11'>
                <h4 className='w3-center'>
                  {profile.lastName} {profile.firstName}
                </h4>
              </div>

              <div className='w3-col m1 s1'>
                <button
                  onClick={() => toggleProfileBox(!displayProfileBox)}
                  type='button'
                  className='w3-button w3-block w3-theme-l1 w3-left-align'
                >
                  {displayProfileBox && (
                    <i className='fa fa-arrow-up fa-fw w3-margin-right'></i>
                  )}
                  {!displayProfileBox && (
                    <i className='fa fa-arrow-down fa-fw w3-margin-right'></i>
                  )}
                </button>
              </div>
            </div>
            {displayProfileBox && (
              <Fragment>
                <div className='w3-row'>
                  <div className='w3-col m1 s1'>
                    <Link to='UserParams'>
                      <i className='fa fa-cog '></i>{' '}
                    </Link>
                  </div>
                  <div className='w3-col m7 s7'>
                    <p className='w3-center'>
                      <img
                        src={getAvatar(profile.sex)}
                        className='w3-circle'
                        style={{ height: '106px', width: '106px' }}
                        alt='Avatar'
                      />
                    </p>
                  </div>
                  <div className='w3-col m4 s4'>
                    <Button
                      id={'taskCreateBox'}
                      className='redEnvelope'
                      icon='newfolder'
                      text='Feladat'
                      onClick={showTaskCreate}
                      visible={true}
                      width='100%'
                    />
                    <hr />
                    <Button
                      id={'messageBox'}
                      className='redEnvelope'
                      icon='message'
                      text='Üzenet'
                      onClick={showInfo}
                      visible={taskVisible}
                      width='100%'
                    />
                  </div>
                </div>
                <hr />
                <div className='w3-col m8 s8'>
                  <p>
                    <i className='fa fa-school fa-fw w3-margin-right w3-text-theme'></i>{' '}
                    {profile.school}
                  </p>
                  <p>
                    <i className='fa fa-home fa-fw w3-margin-right w3-text-theme'></i>{' '}
                    {profile.zip} {profile.city} {profile.address}
                  </p>
                  <p>
                    <i className='fa fa-birthday-cake fa-fw w3-margin-right w3-text-theme'></i>{' '}
                    {profile.birthday}
                  </p>
                  <p>
                    <i className='fa fa-pencil fa-fw w3-margin-right w3-text-theme'></i>{' '}
                    {profile.aboutme}
                  </p>
                  <p>
                    <Link to='create-profile'>
                      <i className='fa fa-cog fa-fw w3-margin-right w3-text-theme'></i>
                      {' Profil szerkesztése'}
                    </Link>
                  </p>
                  <p>
                    <Link to='change-password'>
                      <i className='fa fa-key fa-fw w3-margin-right w3-text-theme'></i>
                      {' Jelszó módosítása'}
                    </Link>
                  </p>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div>
          <Fragment>
            <Popup
              visible={popupVisible}
              onHiding={hideInfo}
              dragEnabled={false}
              hideOnOutsideClick={true}
              showCloseButton={false}
              showTitle={true}
              title='Elvégzendő feladatok'
              container='.dx-viewport'
              width={600}
              height={500}
            >
              <Position at='bottom' my='left top' of={positionOf} />

              <ToolbarItem
                widget='dxButton'
                toolbar='bottom'
                location='after'
                options={closeButtonOptions}
              />
              <MessagePopup popupTasks={tasks} user={user} />
            </Popup>
          </Fragment>
        </div>
        <div>
          <TaskCreatePopup
            taskPopupVisible={taskCreateVisible}
            taskPosition={positionOfCreateTask}
            buttonOption={closeTaskCreateButtonOptions}
            createTaskOptions={TaskCreateButtonOptions}
            toCreateTask={task}
            hideEvent={hideTaskCreate}
          />
        </div>
      </Fragment>
    );
  }
};

export default ProfileBox;
