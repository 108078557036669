import { createStore } from 'devextreme-aspnet-data-nojquery';
import List from 'devextreme-react/list';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrentUserProfile } from '../../actions/userProfile';
import { backendHOST } from '../../client-config/client-config';
import ActPriceList from '../controls/ActPriceList';
import LoggedInUsers from '../controls/LoggedInUsers';
import TodaysLessonsItem from '../controls/TodaysLessonsItem';
import { TodaysLessonPopup } from '../controls/TodaysLessonsPopup';
import LoadingSpinner from '../layout/LoadingSpinner';
import CapacityStat from './CapacityStat';

const loginUsers = createStore({
  key: 'type',
  loadMode: 'raw',
  loadUrl: `${backendHOST}/api/stats/LoggedInUsers`,

  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
});

const actPricelist = createStore({
  key: 'type',
  loadMode: 'raw',
  loadUrl: `${backendHOST}/api/scriptantiaAdmin/ActualPriceList`,

  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
});

const todaysLessons = createStore({
  key: 'type',
  loadMode: 'raw',
  loadUrl: `${backendHOST}/api/stats/TodaysLessons`,
  loadParams: { sort: { selector: 'date_start', desc: false } },
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
});

const Dashboard = ({
  getCurrentUserProfile,

  auth: { user },
  userProfile: { profile, loading },
}) => {
  useEffect(() => {
    getCurrentUserProfile();
  }, []);

  const [TodaysLessonPopupVisible, toggleTodaysLessonPopup] = useState(false);
  const [positionOf, setTodaysLessonPopupPosition] = useState('');
  const [TodaysLessonActualLesson, setTodaysLessonActualLesson] = useState({});

  const TodaysLessonhideInfo = (e) => {
    toggleTodaysLessonPopup(false);
    setTodaysLessonActualLesson({});
  };

  const TodaysLessonsShowInfo = (e) => {
    toggleTodaysLessonPopup(true);
    setTodaysLessonPopupPosition(`#maiOrak`);
  };

  const closeTodaysLessonsButtonOptions = {
    text: 'Bezár',
    onClick: TodaysLessonhideInfo,
  };

  const TodaysLessonItemClick = (e) => {
    const { itemData } = e;

    setTodaysLessonActualLesson(itemData);
    TodaysLessonsShowInfo();
  };

  return loading && profile === null ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='w3-col m4'>
          <div className='dx-row'>
            <div className='dx-card dx-round dx-white'>
              <h3>Mai óráim</h3>
              <List
                id={'maiOrak'}
                dataSource={todaysLessons}
                height={600}
                itemRender={TodaysLessonsItem}
                onItemClick={TodaysLessonItemClick}
              ></List>
            </div>
          </div>
        </div>
        <div className='w3-col m4'>
          <div className='dx-row'>
            <div className='dx-card dx-round dx-white'>
              <h3>Aktuális árlista</h3>
              <List
                dataSource={actPricelist}
                height={250}
                itemRender={ActPriceList}
              ></List>
            </div>
          </div>
        </div>
        <div className='w3-col m4'>
          <div className='dx-row'>
            <div className='dx-card dx-round dx-white'>
              <h3>Belépett felhasználók</h3>
              <List
                dataSource={loginUsers}
                height={250}
                itemRender={LoggedInUsers}
              ></List>
            </div>
          </div>
        </div>
        <div className='w3-col m8'>
          <div className='dx-row'>
            <CapacityStat pTanev='2024/2025' oktatoSelect={false} />
          </div>
        </div>

        <Fragment>
          <Popup
            visible={TodaysLessonPopupVisible}
            onHiding={TodaysLessonhideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            title='Jelenlét'
            container='.dx-viewport'
            width={800}
            height={600}
          >
            <Position at='top' my='left top' of={positionOf} />

            <ToolbarItem
              widget='dxButton'
              toolbar='bottom'
              location='befor'
              options={closeTodaysLessonsButtonOptions}
            />
            <TodaysLessonPopup popupItems={TodaysLessonActualLesson} />
          </Popup>
        </Fragment>
      </div>

      {/* Page container - begin */}
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentUserProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userProfile: state.userProfile,
});

export default connect(mapStateToProps, {
  getCurrentUserProfile,
})(Dashboard);
