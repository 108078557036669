export const boolEnum = [
  {
    id: 0,
    text: 'Nem',
  },
  {
    id: 1,
    text: 'Igen',
  },
];

export const AlertTypes = {
  SUCCESS: 'success',
  FAILED: 'danger',
};
