import { createStore } from 'devextreme-aspnet-data-nojquery';
import Form, { Item } from 'devextreme-react/form';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import React, { Fragment } from 'react';
import { backendHOST } from '../../../client-config/client-config.js';

const url = backendHOST + '/api/scriptantiaAdmin';
const urlOktato = '/Teacher';
const oktato = createStore({
  key: 'id_oktato_real',
  loadMode: 'raw',
  loadUrl: `${url}${urlOktato}`,

  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
});

export class TaskCreatePopup extends React.Component {
  constructor(props) {
    super(props);

    //   this.showInfo = () => this.props.showInfo(props.employee);

    this.validateForm = (e) => {
      e.component.validate();
    };

    this.notesEditorOptions = { height: 120 };
    this.teacherEditorOptions = {
      dataSource: oktato,
      valueExpr: 'id',
      displayExpr: 'text',
      searchEnabled: true,
      value: '',
    };
  }

  render() {
    return (
      <Fragment>
        <Popup
          visible={this.props.taskPopupVisible}
          onHiding={this.props.hideEvent}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title='Feladat létrehozása'
          container='.dx-viewport'
          width={500}
          height={480}
        >
          <Position at='bottom' my='left top' of={this.props.taskPosition} />

          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            options={this.props.buttonOption}
          />
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            options={this.props.createTaskOptions}
            tasksToCreate={this.task}
          />

          <Form
            onContentReady={this.validateForm}
            colCount={2}
            id='form'
            formData={this.props.toCreateTask}
          >
            <Item
              label={{ text: 'Feladat' }}
              dataField='task_title'
              colSpan={2}
            />
            <Item
              label={{ text: 'Leírás' }}
              dataField='task_descr'
              colSpan={2}
              editorType='dxTextArea'
              editorOptions={this.notesEditorOptions}
            />
            <Item
              dataField='task_recipient'
              editorType='dxSelectBox'
              label={{ text: 'Címzett' }}
              editorOptions={this.teacherEditorOptions}
            />
          </Form>
        </Popup>
      </Fragment>
    );
  }
}
