import axios from 'axios';
import React from 'react';
import { backendHOST } from '../../client-config/client-config';
import CreateHomeWork from '../forms/Homework/CreateHomework';
import './TodaysLessonsPopup.scss';

export class TodaysLessonPopup extends React.Component {
  constructor(props) {
    super(props);

    //   this.showInfo = () => this.props.showInfo(props.employee);
    this.setAttendance = this.setAttendance.bind(this);
    this.sendUpdatetoJelenlet = this.sendUpdatetoJelenlet.bind(this);
  }

  render() {
    let items = '';

    if (this.props.popupItems.tanora_jelenlets) {
      items = this.props.popupItems.tanora_jelenlets.map((jelenlet) => (
        <li key={jelenlet.jelenlet_id}>
          <div className='tanora-popup'>
            <div className='diakok-nev'>
              <p>{jelenlet.diak.text}</p>
            </div>

            <div className='diakok-jelenlet'>
              <button
                className={jelenlet.attendance === 1 ? 'attGreen' : 'attNone'}
                data-id={jelenlet.jelenlet_id}
                data-option='attendance'
                onClick={this.setAttendance}
              >
                részt vett
              </button>
              <button
                className={jelenlet.miss1 === 1 ? 'attGreen' : 'attNone'}
                data-id={jelenlet.jelenlet_id}
                data-option='miss1'
                onClick={this.setAttendance}
              >
                lemondta
              </button>
              <button
                className={jelenlet.miss2 === 1 ? 'attGreen' : 'attNone'}
                data-id={jelenlet.jelenlet_id}
                data-option='miss2'
                onClick={this.setAttendance}
              >
                nem szólt / nem jött
              </button>
              <button
                className={jelenlet.deleted === 1 ? 'attGreen' : 'attNone'}
                data-id={jelenlet.jelenlet_id}
                data-option='deleted'
                onClick={this.setAttendance}
              >
                óra törlése
              </button>
            </div>
            <div className='createHomework'>
              <CreateHomeWork
                title={`Házi feladat: ${this.props.popupItems.date_start} - ${this.props.popupItems.tanora_torz.tanora_code}`}
                description={
                  'A mellékelt feladatok megoldásait töltsd fel vagy küld el nekünk a következő óráig! Köszönjük!'
                }
                diak_id={jelenlet.diak.id_user}
                createad_at={new Date()}
                status={10}
                jelenlet_id={jelenlet.jelenlet_id}
              />
            </div>
          </div>
        </li>
      ));
    }

    return (
      <React.Fragment>
        <h4>Diákok</h4>
        <p>A gombok szine a kilépés után változik</p>
        <div>
          <h4>Itt tudsz házi feladatot adni az adott tanórához!</h4>
          <p>
            A lenti szöveges mezőkben az E-mail üzenet tárgy mezőjét és az
            e-mail szövegét tudod megadni. (megszólítás és aláírás nem szükséges
            azt a program hozzáteszi)
          </p>
          <p>
            A program automatikusan elkészíti a mailt mentésre és elküldésre
            nincs szükség. Amint ráhúzod a fileokat a program automatikusan
            elmenti és elküldi a mailt.
          </p>
        </div>
        <div className='list-container'>
          <ul>{items}</ul>
        </div>
        <p></p>
      </React.Fragment>
    );
  }

  sendUpdatetoJelenlet(toUpdate) {
    const cfg = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(
        backendHOST + '/api/scriptantiaAdmin/ScheduleAttendance',
        toUpdate,
        cfg
      )
      .then((data) => {})
      .catch((err) => {
        alert(err, 'Hiba az adatok frissítése során');
      });
  }

  setAttendance(e) {
    ///e.component.editRow(e.row.data.dataIndex);
    let update = {
      JelenletId: e.target.dataset.id,
      type: e.target.dataset.option,
    };
    this.sendUpdatetoJelenlet(update);
  }
}
