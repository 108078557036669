import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import userProfile from './userProfile';
import dataToEdit from './dataToEdit';
import timeTable from './timeTable';
import params from './params';
export default combineReducers({
  alert,
  auth,
  userProfile,
  dataToEdit,
  timeTable,
  params,
});
