import React, { useEffect, useState } from 'react';

import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { getDiakById } from '../../../api/masterdataAPI.';
import { backendHOST } from '../../../client-config/client-config';
import './HomeworksItem.scss';

registerPlugin(
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const url = backendHOST + '/api/upload/homework';

export default function HomeWorkItem({
  id,
  file,
  title,
  description,
  diak_id,
  createad_at,
  status,
  deadline,
  jelenlet_id,
}) {
  const serverSettings = {
    url: url,
    process: {
      headers: {
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      },
      onload: (response) => response.key,
      onerror: (error) => error.message,
    },
  };
  const [diak, setDiak] = useState({});
  useEffect(() => {
    async function getDiak() {
      const res = await getDiakById(diak_id);
      setDiak(res);
    }
    getDiak();
  }, []);

  return (
    <div className='dx-row-padding  row'>
      <React.Fragment>
        <div className='hw-column main'>
          <h4>{title}</h4>
          <p>
            <strong>Diák neve:</strong> {diak.text2}
          </p>
          <p>{description}</p>
        </div>
        <div className='hw-column file'>
          <FilePond
            files={file}
            allowDownloadByUrl={false}
            allowImagePreview={true}
            filePosterHeight={160}
            disabled={true}
            styleButtonRemoveItemPosition='right'
            maxFiles={5}
            server={serverSettings}
            name='files' /* sets the file input name, it's filepond by default */
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </div>
      </React.Fragment>
    </div>
  );
}
