import hu from './texts/hu.json';
import en from './texts/en.json';
import { LANG } from '../client-config/client-config';

const t = (textToTranslate) => {
  var ret_str = '';

  switch (LANG) {
    case 'hu':
      ret_str = hu[textToTranslate];
      break;
    case 'en':
      ret_str = en[textToTranslate];
      break;
    default:
      ret_str = textToTranslate;
  }

  if (ret_str.length === 0) {
    ret_str = textToTranslate;
  }
  return ret_str;
};

export const text = {
  LOGIN_SUCCESSFUL: t('LOGIN_SUCCESSFUL'),
  USER_ALREADY_LOGGED_IN: t('USER_ALREADY_LOGGED_IN'),
};
