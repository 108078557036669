import axios from 'axios';
import { backendHOST } from '../client-config/client-config';
import setAuthToken from '../utils/setAuthToken';

export async function createCampaignDetails(campaignKey, userId) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (localStorage.token) {
    let res;
    try {
      res = await axios.post(
        backendHOST + '/api/mail/CampaignDetails',
        {
          campaignId: campaignKey,
          mailStatus: 10,
          mailToUser: userId,
        },
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        return res.data;
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
