import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../hooks/withRouter';

import { backendHOST } from '../../client-config/client-config';
const stringify = require('json-stringify-safe');

class AppointmentToolTipRestricted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      AppData: props.data.appointmentData,
    };
  }

  render() {
    const { AppData } = this.state;
    const u = this.state.auth.user;

    var logEntry = {
      userId: u.userId,
      id_user: u.ext_id_user,
      access_object: 'DIAK ADATOK',
      request: stringify(AppData),
    };

    const config = { header: { 'Content-Type': 'application/json' } };
    const res = axios.post(
      backendHOST + '/api/auth/ActionLog',
      logEntry,
      config
    );
    if (u.ext_id_user === AppData.oktato.id_user) {
      return (
        <div className='dx-card TimeTableToolTip'>
          <div className='dx-card-header'>
            <h2 className='dx-card-title'>
              {AppData.tanora_torz.tanora_descr}
              {' - Oktató: '}
              {AppData.oktato.last_name} {AppData.oktato.first_name}
            </h2>
            <h3>
              {'Oktató telefonszáma: '}
              {AppData.oktato.userProfile.phone}
            </h3>
          </div>
          <div className='dx-card-body'>
            <h3 className='dx-card-title'>
              {'Időpont: '}
              {AppData.datum}{' '}
              {new Date(AppData.date_start).toLocaleTimeString(
                navigator.language,
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )}
              {'-'}
              {new Date(AppData.date_to).toLocaleTimeString(
                navigator.language,
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )}
              {' => Terem:'}
              {AppData.terem ? AppData.terem.text : ''}
            </h3>

            <h3></h3>
            <h3>Diákok</h3>
            <div>
              {AppData.tanora_jelenlets.map((d, i) => {
                return (
                  <div key={i}>
                    {d.diak.last_name} {d.diak.first_name} - ({d.oradij}) - (
                    Tel.:{d.diak.userProfile && d.diak.userProfile.phone} /
                    szülő: {d.diak.userProfile && d.diak.userProfile.phone2})
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='dx-card TimeTableToolTip'>
          <div className='dx-card-header'>
            <h2 className='dx-card-title'>
              {' Oktató: '}
              {AppData.oktato.last_name} {AppData.oktato.first_name}
            </h2>
            <h3>
              {'Oktató telefonszáma: '}
              {AppData.oktato.userProfile.phone}
            </h3>
          </div>
          <div className='dx-card-body'>
            <h3 className='dx-card-title'>
              {'Időpont: '}
              {AppData.datum}{' '}
              {new Date(AppData.date_start).toLocaleTimeString(
                navigator.language,
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )}
              {'-'}
              {new Date(AppData.date_to).toLocaleTimeString(
                navigator.language,
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )}
              {' => Terem:'}
              {AppData.terem ? AppData.terem.text : ''}
            </h3>
          </div>
        </div>
      );
    }
  }
}

AppointmentToolTipRestricted.propTypes = { auth: PropTypes.object.isRequired };

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(AppointmentToolTipRestricted));
