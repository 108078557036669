import axios from 'axios';
import { Button } from 'devextreme-react/button';
import React from 'react';
import { backendHOST } from '../../../client-config/client-config.js';

export class TaskCloseButton extends React.Component {
  constructor(props) {
    super(props);

    this.markAsReady = this.markAsReady.bind(this);
  }

  render() {
    return (
      <Button
        hint='Feladat elvégezve'
        icon='check'
        visible={true}
        onClick={this.markAsReady}
      ></Button>
    );
  }

  markAsReady(e) {
    const cfg = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const taskToClose = {
      id_task: this.props.taskId,
      id_user: this.props.user.ext_id_user,
    };

    axios
      .post(backendHOST + '/api/taskManager/closeTask', taskToClose, cfg)
      .then((data) => {
        alert('Task is closed');
      })
      .catch((err) => {
        alert(err, 'Hiba a Task generálás során');
      });
  }
}
