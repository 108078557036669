import {
  Column,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import PropTypes from 'prop-types';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';

import { backendHOST } from '../../../../client-config/client-config';

import { logout } from '../../../../actions/auth';
import { withRouter } from '../../../hooks/withRouter';

const url = backendHOST + '/api/scriptantiaAdmin';

function payment(data) {
  alert('HAMAROSAN');
}

const onExportData = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx'
      );
    });
  });
  e.cancel = true;
};

const FormDiakDijak = ({ history, logout }) => {
  const dataSource = createStore({
    key: 'userId',
    loadUrl: `${url}/DiakDijak`,
    loadMode: 'raw',
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    // onAjaxError: onDataErrorOccurred,
  });

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Diák óradíjak áttekintő</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                // remoteOperations={true}
                keyExpr='userId'
                key='userId'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                onExporting={onExportData}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <Export enabled={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />

                <Editing
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={false}
                  repaintChangesOnly
                  mode='row'
                ></Editing>
                <Column
                  dataField='id_user'
                  caption='DiakAzon.'
                  visible={true}
                  width={60}
                ></Column>
                <Column
                  dataField='diak.text'
                  caption='Diák neve.'
                  visible={true}
                ></Column>
                <Column
                  dataField='diak.payment_frequency'
                  caption='Fiz.mód'
                  visible={true}
                ></Column>
                <Column
                  dataField='tantargy.tantargy_descr'
                  caption='Tantárgy'
                  visible={true}
                ></Column>
                <Column
                  dataField='DiakDija'
                  caption='Óradíjak - aktuális'
                  visible={true}
                ></Column>
                <Column
                  dataField='DiakTavalyiDija'
                  caption='Óradíjak - tavaly'
                  visible={true}
                ></Column>
                <Column
                  dataField='AlapDij'
                  caption='Listaár'
                  visible={true}
                ></Column>
                <Column
                  dataField='OraTervben'
                  caption='Van betervezett óra'
                  visible={true}
                ></Column>
                <Column
                  dataField='Difference'
                  caption='Eltérés'
                  visible={true}
                ></Column>
                <Column
                  dataField='Increase'
                  caption='Emelés'
                  visible={true}
                ></Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormDiakDijak.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(FormDiakDijak));
