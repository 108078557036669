export default function TodaysLessonsItem(item) {
  return (
    <div
      className='dx-card dx-round'
      //color='red'
      //style={'background-color:red'}
      style={{
        backgroundColor: `${item.terem.color}`,
      }}
    >
      <h5>
        {item.date_start} / {item.time_to}
      </h5>
      <p>
        {item.tanora_torz.tanora_code} - {item.tanora_torz.tanora_descr}
      </p>
      <p>{item.terem.descr}</p>
    </div>
  );
}
