import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Fragment, useEffect, useState } from 'react';
import PermissionProvider from '../../PermissionProvider/PermissionProvider';
import Restricted from '../../PermissionProvider/Restricted';
import { logout } from '../../actions/auth.js';
import {
  setTimeTableClose,
  setTimeTableOpen,
} from '../../actions/timeTable.js';
import { getDBVersion } from '../../api/version.js';
import logo from '../../img/logo.png';

const fetchPermission = (user) => async (permission) => {
  // Simulate a delay from a request

  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (user && user.permissions) {
    return user.permissions.includes(permission);
  } else {
    return false;
  }
};

const Navbar = ({
  auth: { user, isAuthenticated, loading },
  timeTable: { timeTable, TTloading },
  logout,
  setTimeTableOpen,
  setTimeTableClose,
}) => {
  //const { unloadContext } = useSiteContext();
  const [dbType, setDBType] = useState('U');
  const [dbVersion, setDBVersion] = useState(0);
  useEffect(() => {
    getDBVersion().then((res) => {
      if (res.isOk) {
        console.log(res.data);
        setDBType(res.data.environement);
        setDBVersion(res.data.version);
      }
    });
  }, []);

  const authLinks = (
    <PermissionProvider fetchPermission={fetchPermission(user)}>
      <ul className='HeaderMenu'>
        <li>
          <Restricted to='scriptantia.timetable'>
            <Link to='/scriptantia-timetable'>
              <i className='fas fa-calendar'></i>{' '}
              <span className='hide-sm' onClick={showOrarend}>
                Órarend
              </span>
            </Link>
          </Restricted>
          <Restricted to='scriptantia.capacityPlan'>
            <Link to='/scriptantia-capacityPlan'>
              <i className='fas fa-calendar'></i>{' '}
              <span className='hide-sm' onClick={showCapacityPlan}>
                Kapacitás áttekintés
              </span>
            </Link>
          </Restricted>
        </li>
        <li>
          <Link
            to='/dashboard'
            onClick={(e) => {
              if (timeTable.status === 'open') {
                setTimeTableClose();
              }
            }}
          >
            <i className='fas fa-user'></i>{' '}
            <span className='hide-sm'>Profilom</span>
          </Link>
        </li>
        <li>
          <Link to='/' onClick={logout}>
            <i className='fas fa-sign-out-alt'></i>{' '}
            <span className='hide-sm'>Logout</span>
          </Link>
        </li>
      </ul>
    </PermissionProvider>
  );

  function showOrarend() {
    setTimeTableOpen();
  }

  function showCapacityPlan() {
    setTimeTableOpen();
  }
  const guestLinks = (
    <ul className='HeaderMenu'>
      <li>
        <Link to='/register'>Register</Link>
      </li>
      <li>
        <Link to='/login'>Login</Link>
      </li>
    </ul>
  );

  return (
    <nav className='navbar bg-dark'>
      <h3>
        <Link
          to='/'
          onClick={(e) => {
            if (timeTable.status === 'open') {
              setTimeTableClose();
            }
          }}
        >
          <img
            src={logo}
            className='w3-circle'
            style={{ height: '60px', width: '60px' }}
            alt='Logo'
          />{' '}
          Scriptantia Admin
        </Link>
      </h3>
      <p>{` v.${process.env.REACT_APP_VERSION}-(${dbType}-${dbVersion})`}</p>
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  setTimeTableOpen: PropTypes.func.isRequired,
  setTimeTableClose: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  timeTable: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  timeTable: state.timeTable,
});

export default connect(mapStateToProps, {
  logout,
  setTimeTableOpen,
  setTimeTableClose,
})(Navbar);
