import { createStore } from 'devextreme-aspnet-data-nojquery';
import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  Lookup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';
import { Fragment } from 'react';
import { onDataErrorOccurred } from '../../../../actions/errorHandler';
import { backendHOST } from '../../../../client-config/client-config';
import { useLocation, useNavigate } from 'react-router';

const url = backendHOST + '/api/scriptantiaAdmin';
const urlEnd = '/OktatoSkills';

// Let's create component [[MypropDestructuringMessage]]

const FormTeacherSkills = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  locale(navigator.language);
  const { userId, last_name, first_name } = location.state && location.state;

  function getDisplayExpr(item) {
    return item ? `${item.type} ${item.descr}` : '';
  }
  const dataSource = createStore({
    key: 'id',
    loadUrl: `${url}/OktatoSkills`,
    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          id_oktato: userId,
        };
      }
    },
    onAjaxError: onDataErrorOccurred,
  });

  const skills = createStore({
    key: 'id_skill',
    loadMode: 'raw',
    loadUrl: `${url}/Skills`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    onAjaxError: onDataErrorOccurred,
  });

  const dateEditorOptions = {
    type: 'date',
    displayFormat: 'yyyy.MM.dd',
    useMaskBehavior: true,
    calendarOptions: {
      firstDayOfWeek: 1,
    },
  };

  const onInitNewRow = (e) => {
    e.data.id_oktato = userId;
  };

  return (
    <Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col m12'>
          <div className='w3-card w3-round '>
            <div className='w3-container w3-padding'>
              <button onClick={() => navigate(-1)}>Visszalépés</button>
              <h5 className='w3-opacity'>{last_name + ' ' + first_name}</h5>
              <h6 className='w3-opacity'>Oktató készségek </h6>
            </div>
            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr='id'
                key={'id'}
                allowColumnReordering={true}
                onInitNewRow={onInitNewRow}
              >
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Editing
                  allowAdding={true}
                  allowUpdating={true}
                  allowDeleting={true}
                  repaintChangesOnly
                  mode='row'
                ></Editing>
                <Column
                  dataField='id_oktato'
                  caption='Kód'
                  allowEditing={false}
                  visible={false}
                ></Column>
                <Column
                  dataField='id_skill'
                  caption='Készség'
                  allowEditing={true}
                >
                  <Lookup
                    dataSource={skills}
                    valueExpr='id_skill'
                    displayExpr='skill_name'
                  ></Lookup>
                </Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormTeacherSkills;
