import { Fragment, useEffect, useState } from 'react';

import { locale } from 'devextreme/localization';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { logout } from '../../../actions/auth';
import { backendHOST } from '../../../client-config/client-config';
import { withRouter } from '../../hooks/withRouter';
import NewHomework from './NewHomework';

import 'filepond/dist/filepond.min.css';
import { registerPlugin } from 'react-filepond';

import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { getHomeworks } from '../../../api/homework';
import HomeWorkItem from './HomeWorkItem';

import CheckBox from 'devextreme-react/cjs/check-box';
import './Homeworks.scss';
import HwReplyItem from './HwReplyItem';

//import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

registerPlugin(
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile,
  FilePondPluginImagePreview
);

//registerPlugin(FilePondPluginFileEncode);

const url = backendHOST + '/api/upload/homework';

const FormCreateHomeWork = ({ history, logout, auth }) => {
  locale(navigator.language);

  const [homeWorks, setHomeWorks] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);
  const [displayReplied, setDisplayReplied] = useState(false);

  const [diakNev, setDiakNev] = useState('');

  useEffect(() => {
    if (!auth.isAuthenticated) {
      logout();
    }
    async function apiCall() {
      const apiResponse = await getHomeworks({
        displayAll: displayAll,
        displayReplied: displayReplied,
      });
      if (apiResponse) {
        console.log(apiResponse.data);
        setHomeWorks(apiResponse.data);
      }
    }
    apiCall();
  }, [displayAll, displayReplied]);

  function onDisplayAllChanged(e) {
    setDisplayAll(e.value);
  }
  function onDisplayRepliedChanged(e) {
    setDisplayReplied(e.value);
  }
  function onDiakNameChanged(e) {
    setDiakNev(e.target.value);
  }

  function onStatusChange(e) {
    console.log('Clicked', e.target.getAttribute('hw-id'));
  }

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding hw-header-box'>
              <div className='hw-header-infos'>
                <h6 className='dx-opacity'>Házi feladatok megtekintése</h6>
                <div className='hw-legend'>
                  <p>
                    <i className='fa-regular fa-face-frown-open hw-error'></i>{' '}
                    Nem sikerült létrehozni a mail küldést - klikkelj az ikonra
                    és próbáld újra!
                  </p>
                  <p>
                    <i className='fa-solid fa-envelope'></i> E-mail küldés
                    előjegyezve 15 percen belül elküldésre kerül
                  </p>
                  <p>
                    <i className='fa-solid fa-paper-plane'></i> E-mail küldés
                    megtörtént
                  </p>
                  <p>
                    <i className='fa-regular fa-square-check hw-replied'></i> A
                    házi feladatot visszaküldte a diákod
                  </p>
                  <p>
                    <i className='fa-solid fa-triangle-exclamation hw-error'></i>{' '}
                    Rendszerhiba történt szólj a főnöknek vagy próbáld újra az
                    ikonra klikkeléssel!
                  </p>
                </div>
                <div className='hw-filter'>
                  <CheckBox
                    defaultValue={displayAll}
                    text='Összes oktató kiküldött feladatainak megjelenítése'
                    onValueChanged={onDisplayAllChanged}
                    visible={auth.user.permissions.includes(
                      'homework.display.all'
                    )}
                  />

                  <CheckBox
                    defaultValue={displayAll}
                    text='Visszaküldött feladatok megjelenítése'
                    onValueChanged={onDisplayRepliedChanged}
                  />

                  <p>
                    Diák keresése:{' '}
                    <input
                      type='text'
                      onChange={onDiakNameChanged}
                      value={diakNev}
                    ></input>
                  </p>
                </div>
              </div>
              <div className='dx-viewport homerwork-create hw-create-new'>
                <div className='dx-viewport'>
                  <NewHomework />
                </div>
              </div>
            </div>

            <div className='dx-viewport homework-list'>
              {homeWorks &&
                homeWorks.map((hw) => {
                  if (hw.diak.includes(diakNev) || diakNev === '') {
                    return (
                      <div className='homework-items' key={hw.id}>
                        <div className='homework-item'>
                          <HomeWorkItem
                            key={hw.id}
                            id={hw.id}
                            file={hw.file}
                            title={hw.homework_title}
                            description={hw.homework_description}
                            diak_id={hw.id_diak}
                            createad_at={hw.created_at}
                            status={hw.homework_status}
                            deadline={hw.homework_deadline}
                            jelenlet_id={hw.jelenlet_id}
                          />
                          <div className='hw-reply-items'>
                            {hw.replies &&
                              hw.replies.map((reply) => {
                                return (
                                  <div className='hw-reply-item'>
                                    <HwReplyItem
                                      id={reply.id}
                                      file={reply.file}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className='homework-status'>
                          <button
                            className='homework-button'
                            type='button'
                            hw-id={hw.id}
                            onClick={onStatusChange}
                          >
                            {hw.homework_status === 10 ? (
                              <i className='fa-regular fa-face-frown-open hw-error'></i>
                            ) : hw.homework_status === 20 ? (
                              <i className='fa-solid fa-envelope'></i>
                            ) : hw.homework_status === 30 ? (
                              <i className='fa-solid fa-paper-plane'></i>
                            ) : hw.homework_status === 50 ? (
                              <i className='fa-regular fa-square-check hw-replied'></i>
                            ) : (
                              <i className='fa-solid fa-triangle-exclamation hw-error'></i>
                            )}
                          </button>
                          <div className='hw-button-text'>
                            {hw.homework_status === 10 ? (
                              <p>Mail küldés hiba</p>
                            ) : hw.homework_status === 20 ? (
                              <p>Mail küldés folyamatban</p>
                            ) : hw.homework_status === 30 ? (
                              <p>Mail küldés sikeres</p>
                            ) : hw.homework_status === 50 ? (
                              <p>Feladat visszaküldve</p>
                            ) : (
                              <p>!! Hiba !!</p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormCreateHomeWork.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormCreateHomeWork)
);
