import { Fragment } from 'react';

import { connect } from 'react-redux';
import { locale } from 'devextreme/localization';
import {
  DataGrid,
  Column,
  Editing,
  HeaderFilter,
  SearchPanel,
  FilterPanel,
  Popup,
  Form,
  Button,
  Item,
  Scrolling,
  Paging,
  Lookup,
} from 'devextreme-react/data-grid';

import PropTypes from 'prop-types';

import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { backendHOST } from '../../../client-config/client-config';

import { logout } from '../../../actions/auth';
import { PaymentMethods } from '../../../utils/enums/userTypes';
import { withRouter } from '../../hooks/withRouter';

const url = backendHOST + '/api/invoice';
const urlEnd = '/Invoice';

const FormManualInvoice = ({ history, logout, auth }) => {
  locale(navigator.language);
  const dataSource = createStore({
    key: 'id_bizonylat',

    loadUrl: `${url}${urlEnd}`,

    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    // onAjaxError: onDataErrorOccurred,
  });
  const urlAdmin = backendHOST + '/api/scriptantiaAdmin';
  const diakok = createStore({
    key: 'id_diak',
    loadMode: 'raw',
    loadUrl: `${urlAdmin}/Student`,
    loadParams: { sort: { selector: 'text', desc: false } },
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          uType: 'D',
        };
      }
    },
  });

  const dateEditorOptions = {
    type: 'date',
    displayFormat: 'yyyy.MM.dd',
    useMaskBehavior: true,
    calendarOptions: {
      firstDayOfWeek: 1,
    },
  };

  const onInitNewRow = (e) => {
    e.data.datum = new Date();

    e.data.created_by_user = auth.user.ext_id_user;
  };

  function notInvoiced(e) {
    return e.row.data.invoiced === 0;
  }

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Kézi számlázás</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr='id_bizonylat'
                key='id_bizonylat'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                onInitNewRow={onInitNewRow}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Editing
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  repaintChangesOnly
                  mode='popup'
                >
                  {' '}
                  <Popup
                    title='Kész számla készítése'
                    showTitle={true}
                    width={800}
                    height={500}
                  />
                  <Form colCount={1}>
                    <Item
                      caption='Számlázandó tétel'
                      itemType='group'
                      colCount={8}
                      colSpan={1}
                    >
                      <Item dataField='id_diak' colSpan={3} />
                      <Item dataField='datum' colSpan={2} />
                      <Item dataField='fiz_mod' colSpan={3} />

                      <Item dataField='fizetett_orak_db' colSpan={1} />
                      <Item dataField='osszeg' colSpan={1} />
                      <Item dataField='descr' colSpan={6} />
                    </Item>
                    <Item
                      caption='Technikai adatok'
                      itemType='group'
                      colCount={8}
                      colSpan={1}
                    >
                      <Item dataField='id_bizonylat' colSpan={1} />
                      <Item dataField='created_timestamp' colSpan={2} />

                      <Item dataField='created_by_user' colSpan={3} />
                    </Item>
                  </Form>
                </Editing>

                <Column
                  dataField='id_bizonylat'
                  caption='ID'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column dataField='id_diak' caption='Diák' allowEditing={true}>
                  <Lookup
                    dataSource={diakok} // {{ store: diakok, sort: 'last_name' }}
                    valueExpr='id'
                    displayExpr='text'
                  ></Lookup>
                </Column>
                <Column
                  dataField='datum'
                  caption='Dátum'
                  allowEditing={true}
                  visible={true}
                  editorType='dxDateBox'
                  editorOptions={dateEditorOptions}
                  defaultSortOrder='desc'
                  displayFormat='yyyy.MM.dd'
                  useMaskBehavior={true}
                  dataType='date'
                ></Column>
                <Column
                  dataField='descr'
                  caption='Tétel'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='osszeg'
                  caption='Összeg'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='fizetett_orak_db'
                  caption='Óraszám'
                  allowEditing={true}
                  visible={false}
                ></Column>
                <Column
                  dataField='invoiced'
                  caption='Paraméter érték'
                  allowEditing={notInvoiced}
                  visible={false}
                ></Column>
                <Column
                  dataField='invoice_nr'
                  caption='Számlaszám'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='fiz_mod'
                  caption='Fizetési mód'
                  allowEditing={true}
                >
                  <Lookup
                    dataSource={PaymentMethods}
                    valueExpr='key'
                    displayExpr='name'
                  ></Lookup>
                </Column>
                <Column
                  dataField='created_by_user'
                  caption='Létrehozta'
                  allowEditing={false}
                  visible={false}
                ></Column>
                <Column
                  dataField='created_timestamp'
                  caption='Létrehozás dátuma'
                  allowEditing={false}
                  visible={false}
                ></Column>
                <Column name='process2' type='buttons'>
                  <Button name='edit' visible={notInvoiced}></Button>
                  <Button name='delete' visible={notInvoiced}></Button>
                </Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormManualInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormManualInvoice)
);
