import { Fragment, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';

import { getRegistrations } from '../../../../api/registration/registration';
import { backendHOST } from '../../../../client-config/client-config';
import { withRouter } from '../../../hooks/withRouter';
import RegistrationItem from './RegistrationItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const url = backendHOST + '/api/scriptantiaAdmin';
const urlEnd = '/Registrations';

const FormRegistrations = () => {
  const [registrations, setRegistrations] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getRegistrations(displayAll);
      if (apiResponse) {
        setRegistrations(apiResponse);
      }
    }
    apiCall();
  }, [displayAll]);

  function onDisplayAllChanged(e) {
    setDisplayAll(e.target.checked);
  }

  return (
    <Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col m12'>
          <div className='w3-card w3-round '>
            <div className='w3-container w3-padding'>
              <h6 className='w3-opacity'>Online regisztrációk</h6>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayAll}
                    onChange={onDisplayAllChanged}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Régi regisztrációk megjelenítése'
              />
            </div>
            <div className='w3-container w3-padding'>
              {registrations &&
                registrations.map((reg) => {
                  return <RegistrationItem key={reg.id} dataItem={reg} />;
                })}
            </div>
            <div className='dx-viewport'></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormRegistrations.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(FormRegistrations));
