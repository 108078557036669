import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { backendHOST } from '../client-config/client-config';
import {
  GET_PROFILE,
  PROFILE_ERROR,
  PASSWORD_CHANGED,
  PASSWORD_ERROR,
  PASSWORD_NOT_MATCH,
  PASSWORD_SAME_AS_OLD,
  GET_TASKS,
  TASKS_ERROR,
} from './types';

//get current users profile
export const getCurrentUserProfile = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const res = await axios.get(backendHOST + '/api/userData/UserProfile');

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

export const getCurrentUserTasks = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const res = await axios.get(backendHOST + '/api/taskManager/UserTasks');

    dispatch({
      type: GET_TASKS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

// Create or Update profile
export const saveProfile = (formData, history) => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = { header: { 'Content-Type': 'application/json' } };

    const res = await axios.post(
      backendHOST + '/api/userData/UserProfile',
      formData,
      config
    );

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
    dispatch(setAlert('Profile Updated', 'success'));

    history.push('/dashboard');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const changePassword = (formData, history) => async (dispatch) => {
  if (formData.password !== formData.password2) {
    dispatch({
      type: PASSWORD_NOT_MATCH,
    });
    dispatch(setAlert('A megadott két új jelszó nem egyezik', 'danger'));
  }

  if (
    formData.password === formData.password2 &&
    formData.password === formData.password_old
  ) {
    dispatch({
      type: PASSWORD_SAME_AS_OLD,
    });
    dispatch(
      setAlert('A megadott új jelszó nem lehet ugyanaz mint a régi', 'danger')
    );
  }

  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = { header: { 'Content-Type': 'application/json' } };

    const res = await axios.post(
      backendHOST + '/api/userData/ChangePassword',
      formData,
      config
    );

    dispatch({
      type: PASSWORD_CHANGED,
      payload: res.data,
    });
    dispatch(setAlert('Jelszó sikeresen megváltoztatva', 'success'));

    history.push('/dashboard');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PASSWORD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
