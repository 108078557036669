import axios from 'axios';
import { backendHOST } from '../client-config/client-config';

const createLogEntry = async (logItem) => {
  const config = { header: { 'Content-Type': 'application/json' } };
  const ignoredRoutes = ['/', '/login'];

  if (
    logItem.access_object === 'SITE_USAGE' &&
    !ignoredRoutes.includes(logItem.request)
  ) {
    axios.post(backendHOST + '/api/auth/ActionLog', logItem, config);
  }
};

export default createLogEntry;
