import {
  DATA_GET,
  DATA_ERROR,
  DATA_CLEAR,
  SET_EDIT_ROW_KEY,
  SET_IMAGE_LINK_KEY,
} from '../actions/types';

const initialState = {
  dataContent: null,
  editRowKey: null,
  imageLink: null,
  dataType: '',
  loading: true,
  error: {},
};

export default function dataToEdit(state = initialState, action) {
  const { type, payload, dt } = action;
  switch (type) {
    case DATA_GET:
      return {
        ...state,
        dataContent: payload,
        loading: false,
        dataType: dt,
      };
    case DATA_ERROR:
      return {
        ...state,
        dataContent: null,
        error: payload,
        loading: false,
        dataType: '',
      };
    case DATA_CLEAR:
      return {
        ...state,
        dataContent: null,
        editRowKey: null,
        imageLink: null,
        userRoles: [],
        isAdmin: false,
        dataType: '',
      };
    case SET_EDIT_ROW_KEY:
      return {
        ...state,
        editRowKey: payload,
      };
    case SET_IMAGE_LINK_KEY:
      return {
        ...state,
        imageLink: payload,
      };
    default:
      return state;
  }
}
