import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  LoadPanel,
  SearchPanel,
  Item as GridPopupItem,
  Toolbar,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { CheckBox } from 'devextreme-react/check-box';
import { TreeList, Selection, FilterRow } from 'devextreme-react/tree-list';
import 'devextreme/data/odata/store';
import 'devextreme-react/html-editor';
import { locale } from 'devextreme/localization';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import Button from 'devextreme-react/button';
import { backendHOST } from '../../../client-config/client-config';
import { withRouter } from '../../hooks/withRouter';
import { createCampaignDetails } from '../../../api/campaign';

const url = backendHOST + '/api/mail';
const urlEnd = '/CampaignDetails';
const dateEditorOptions = {
  type: 'date',
  displayFormat: 'yyyy-MM-dd',
  useMaskBehavior: true,
};
function getDataSource(id) {
  return {
    store: createStore({
      key: 'id',
      loadUrl: `${url}${urlEnd}`,
      insertUrl: `${url}${urlEnd}`,
      updateUrl: `${url}${urlEnd}`,
      deleteUrl: `${url}${urlEnd}`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-auth-token': localStorage.token,
          'Access-Control-Allow-Origin': '*',
        };
        if (method === 'load') {
          ajaxOptions.dataType = 'json';
          ajaxOptions.data = {
            campaignId: id,
          };
        }
      },
      // onAjaxError: onDataErrorOccurred,
    }),
  };
}
const FormMailCampaignDetails = (props) => {
  locale(navigator.language);
  const [popupVisible, togglePopup] = useState(false);
  const [positionOf, setPopupPosition] = useState('');
  const [selectedRowKeys, setSelectedRowkeys] = useState([]);
  const [showAllUsers, toggleAllUsers] = useState(false);

  const users = createStore({
    key: 'id_user',
    loadUrl: backendHOST + '/api/auth/user',
    loadMode: 'raw',
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          forMailCampaign: 1,
          allUsers: showAllUsers,
          campaignId: props.data.key,
        };
      }
    },
  });

  function onSelectionChanged(e) {
    setSelectedRowkeys(e.selectedRowKeys);
  }

  const dataSource = getDataSource(props.data.key);

  const newRow = (e) => {
    e.data.active = 1;
    e.data.created_at = new Date();
  };

  const hidePopup = (e) => {
    togglePopup(false);
  };

  const showPopup = (e) => {
    togglePopup(true);
    setPopupPosition(`#dataGrid`);
  };

  const closeButtonOptions = {
    text: 'Bezár',
    onClick: hidePopup,
  };

  const insertButtonOption = {
    text: 'Hozzáadás',
    onClick: insertSelectedEntries,
  };
  async function insertSelectedEntries(e) {
    await Promise.all(
      selectedRowKeys.map(async (userId) => {
        await createCampaignDetails(props.data.key, userId);
      })
    );
    hidePopup();
  }

  function addRecipients(e) {
    showPopup();
  }

  function allUsersChanged(e) {
    toggleAllUsers(e.value);
  }

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Kampány címzettek</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr='id'
                key='id'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                onInitNewRow={newRow}
              >
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Editing
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  repaintChangesOnly
                  mode='row'
                ></Editing>
                <Column
                  dataField='user.text'
                  caption='Felhasználó'
                  allowEditing={false}
                  visible={true}
                ></Column>

                <Column
                  dataField='mailStatus'
                  caption='Küldés állapota'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column
                  dataField='mailSentAt'
                  caption='Üzenet elküldve'
                  editorType='dxDateBox'
                  editorOptions={dateEditorOptions}
                  displayFormat='yyyy.MM.dd'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Toolbar>
                  <GridPopupItem location='before'>
                    <Button
                      text='Címzettek hozzáadása'
                      onClick={addRecipients}
                    />
                    <CheckBox
                      value={showAllUsers}
                      text='Összes felhasználó'
                      onValueChanged={allUsersChanged}
                    />
                  </GridPopupItem>
                </Toolbar>
              </DataGrid>
              <Popup
                visible={popupVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title='Címzettek kiválasztása'
                container='.dx-viewport'
                width={800}
                height={600}
              >
                <Position at='top' my='top' of={positionOf} collision='fit' />
                <ToolbarItem
                  widget='dxButton'
                  toolbar='bottom'
                  location='after'
                  options={closeButtonOptions}
                />
                <ToolbarItem
                  widget='dxButton'
                  toolbar='bottom'
                  location='before'
                  options={insertButtonOption}
                />
                <TreeList
                  id='users'
                  dataSource={users}
                  showRowLines={true}
                  showBorders={true}
                  columnAutoWidth={true}
                  //    defaultExpandedRowKeys={expandedRowKeys}
                  selectedRowKeys={selectedRowKeys}
                  keyExpr='id_user'
                  onSelectionChanged={onSelectionChanged}
                >
                  <FilterRow visible={true} />
                  <Selection recursive={false} mode='multiple' />
                  <Column dataField='text' caption='Felhasználó' />
                  <Column dataField='email' caption='e-Mail cím' />
                </TreeList>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormMailCampaignDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormMailCampaignDetails)
);
