import axios from 'axios';
import { backendHOST } from '../../client-config/client-config';
import setAuthToken from '../../utils/setAuthToken';

export async function getRegistrations(displayAll) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (!localStorage.token) {
    return [''];
  }

  let res;
  try {
    res = await axios.get(
      backendHOST + '/api/scriptantiaAdmin/Registrations',
      {
        params: {
          allRegistrations: displayAll,
        },
      },
      config
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    console.error('error', e);
    return [''];
  }
}

export async function createUserFromRegistration(data) {
  console.log('import');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (!localStorage.token) {
    return [''];
  }

  let res;
  try {
    res = await axios.post(
      backendHOST + '/api/userData/createUserFromRegistration',
      data,
      config
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    console.error('error', e);
    return [''];
  }
}
