import builder, { Builder, BuilderComponent } from '@builder.io/react';
import 'katex/dist/katex.min.css';
import { useEffect, useState } from 'react';
import Latex from 'react-latex-next';
import './Builder.scss';

// Replace with your Public API Key.
builder.init('0b11286a1a0f4106b2cf0b003a09840c');

export default function SectionBuilder() {
  const [explanation, setExplanation] = useState(null);

  const LatexItem = (props) => <Latex>{props.content}</Latex>;

  Builder.registerComponent(LatexItem, {
    name: 'LatexItem',
    inputs: [{ name: 'content', type: 'text' }],
    image:
      'https://tabler-icons.io/static/tabler-icons/icons-png/3d-cube-sphere-off.png',
  });

  useEffect(() => {
    builder
      .get('explanation', {
        userAttributes: {
          // To allow targeting different announcements at different pages (URLs)
          urlPath: window.location.pathname,
        },
      })
      .toPromise()
      .then((expl) => setExplanation(expl));
  }, []);

  return (
    <>
      {/* Put your header here. */}

      {explanation && (
        <div className='builder-preview-editor'>
          <BuilderComponent model='explanation' content={explanation} />
        </div>
      )}
      {/* Put the rest of your page here. */}
    </>
  );
}
