const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function LoggedInUsers(item) {
  return (
    <div>
      <p>
        {item.username} / {item.last_name} {item.first_name} - {item.belepve}
      </p>
    </div>
  );
}
