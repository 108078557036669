import { createStore } from 'devextreme-aspnet-data-nojquery';
import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import {
  Column,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Paging,
  Scrolling,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { backendHOST } from '../../../../client-config/client-config';

import { logout } from '../../../../actions/auth';

import {
  MovementTypes,
  PaymentMethods,
} from '../../../../utils/enums/userTypes';
import { withRouter } from '../../../hooks/withRouter';

const url = backendHOST + '/api/scriptantiaAdmin';

function payment(data) {
  alert('HAMAROSAN');
}

const FormTeacherBalance = ({ history, logout, auth }) => {
  const [onlyActiveUser, setActiveUser] = useState(1);
  const dataSource = createStore({
    key: 'oktato',
    loadUrl: `${url}/TeacherBalance`,
    loadMode: 'raw',
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        if (auth.user.permissions.includes('balance.allOktato')) {
          ajaxOptions.dataType = 'json';
          ajaxOptions.data = {
            active: onlyActiveUser,
          };
        } else {
          ajaxOptions.dataType = 'json';
          ajaxOptions.data = {
            active: onlyActiveUser,
            oktato: auth.user.ext_id_user,
          };
        }
      }
    },
    // onAjaxError: onDataErrorOccurred,
  });

  const dateEditorOptions = {
    type: 'date',
    displayFormat: 'yyyy.MM.dd',
    useMaskBehavior: true,
  };

  const GroupCell = (options) => (
    <div>
      {options.value}{' '}
      <Button text='Kifizetés' hint='Kifizetés' onClick={payment}>
        Kifizetés rögzítése
      </Button>
    </div>
  );

  const BalanceCell = (options) => {
    if (options.value < 0) {
      return (
        <div style={{ color: '#FF8033' }}>
          {Number(options.value).toFixed(0)} Ft{' '}
        </div>
      );
    } else {
      return (
        <div style={{ color: '#95D70F' }}>
          {Number(options.value).toFixed(0)} Ft
        </div>
      );
    }
  };

  function onOnlyActiveFilterChanged(e) {
    if (e.value === true) {
      setActiveUser(1);
    } else {
      setActiveUser(0);
    }
  }

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Oktató egyenlegek</h6>
            </div>
            <div className='row'>
              <div className='options col-8'>
                <div className='option'>
                  <CheckBox
                    text='Aktív / Inaktív felhasználók'
                    value={onlyActiveUser === 1}
                    onValueChanged={onOnlyActiveFilterChanged}
                  />
                </div>
              </div>
            </div>
            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                // remoteOperations={true}
                keyExpr='oktato'
                key='oktato'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <Export enabled={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />
                <Summary>
                  <GroupItem
                    column='tipus'
                    displayFormat='{0} tranzakció'
                    alignByColumn
                    summaryType='count'
                  />
                  <GroupItem
                    column='dij'
                    summaryType='sum'
                    displayFormat='{0} Ft'
                    filterValue={0}
                    alignByColumn
                  />
                  <GroupItem
                    column='datum'
                    summaryType='max'
                    alignByColumn
                    displayFormat='utolsó óra: {0}'
                  />
                  <TotalItem
                    column='dij'
                    summaryType='sum'
                    displayFormat='{0} Ft'
                    filterValue={0}
                    alignByColumn
                  />
                </Summary>
                <Editing
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={false}
                  repaintChangesOnly
                  mode='row'
                ></Editing>
                <Column
                  dataField='tanar.text2'
                  caption='Oktató'
                  allowEditing={true}
                  visible={true}
                  displayExpr='tanar.text2'
                  groupIndex={0}
                  groupCellRender={GroupCell}
                  /*groupCellTemplate={function (container, cellInfo) {
                    var test = `${cellInfo.column.caption}: ${cellInfo.text}`;
                    container.append(test);
                  }}*/
                ></Column>
                <Column
                  dataField='year'
                  caption='Év'
                  allowEditing={true}
                  visible={true}
                  groupIndex={1}
                ></Column>
                <Column
                  dataField='month'
                  caption='Hónap'
                  allowEditing={true}
                  visible={true}
                  // groupIndex={2}
                ></Column>
                <Column
                  dataField='datum'
                  caption='Dátum'
                  editorType='dxDateBox'
                  editorOptions={dateEditorOptions}
                  defaultSortOrder='desc'
                ></Column>

                <Column
                  dataField='tipus'
                  caption='Mozgás típusa'
                  visible={true}
                >
                  <Lookup
                    dataSource={MovementTypes}
                    valueExpr='key'
                    displayExpr='name'
                  ></Lookup>
                </Column>
                <Column
                  dataField='fizmod'
                  caption='Fizetési mód'
                  visible={true}
                >
                  <Lookup
                    dataSource={PaymentMethods}
                    valueExpr='key'
                    displayExpr='name'
                  ></Lookup>
                </Column>
                <Column
                  dataField='dij'
                  caption='Összeg'
                  cellRender={BalanceCell}
                ></Column>
                <Column
                  dataField='descr'
                  caption='Megjegyzés'
                  allowSearch={false}
                ></Column>

                <Column
                  dataField='tanar.active'
                  caption='Aktív '
                  visible={false}
                  editorType='dxCheckBox'
                ></Column>
                <Column dataField='creator.text' caption='Rögzítette'></Column>

                <SortByGroupSummaryInfo
                  summaryItem='sum'
                  groupColumn='tanar.text2'
                  sortOrder='asc'
                />
                <SortByGroupSummaryInfo
                  summaryItem='count'
                  groupColumn='year'
                  sortOrder='desc'
                />
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormTeacherBalance.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormTeacherBalance)
);
