import './ActPriceList.scss';
const currencyFormatter = new Intl.NumberFormat('hu-HU', {
  style: 'currency',
  currency: 'HUF',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function ActPriceList(item) {
  return (
    <div>
      <p>
        {item.tantargy_descr} - {currencyFormatter.format(item.dij)}
      </p>
      <p className='behuzas'>
        (Bérlet 5 alk. {currencyFormatter.format(item.dij * 0.9 * 5)})
      </p>
      <p className='behuzas'>
        (Bérlet 10 alk. {currencyFormatter.format(item.dij * 0.9 * 10)})
      </p>
    </div>
  );
}
