import React, { useState } from 'react';
import './RegistrationItem.scss';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { createUserFromRegistration } from '../../../../api/registration/registration';

export default function RegistrationItem({ dataItem }) {
  const [formData, setFormData] = useState({
    id: dataItem.id || '',
    lastName: dataItem.lastName || '',
    firstName: dataItem.firstName || '',
    phoneStudent: dataItem.phoneStudent || '',
    parentLastName: dataItem.parentLastName || '',
    parentFirstName: dataItem.parentFirstName || '',
    phoneParent: dataItem.phoneParent || '',
    email: dataItem.email || '',
    school: dataItem.school || '',
    payment_method: dataItem.payment_method || '',
    payment_type: dataItem.payment_type || '',
    invoiceZip: dataItem.invoiceZip || '',
    city: dataItem.city || '',
    address: dataItem.address || '',
    taxInvoice: dataItem.taxInvoice || '',
    birthday: dataItem.birthday || '',
    class: dataItem.class || '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Update the value of the specific field
    }));
  };

  return (
    <React.Fragment>
      <div className='dx-row-padding row reg-box' key={formData.id}>
        <div>
          {dataItem.status}-{dataItem.createdAt}
        </div>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={formData.lastName}
            name='lastName'
            size='small'
            label={'Vezetéknév'}
            onChange={handleChange}
          />
          <TextField
            value={formData.firstName}
            name='firstName'
            size='small'
            label={'Keresztnév'}
            onChange={handleChange}
          />
          <TextField
            value={formData.phoneStudent}
            name='phoneStudent'
            size='small'
            label={'Telefonszám'}
            onChange={handleChange}
          />
        </Box>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, maxWidth: '52ch' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={formData.email}
            name='email'
            size='small'
            label={'Email'}
            onChange={handleChange}
            sx={{ width: '100%' }}
          />{' '}
          <TextField
            value={formData.school}
            name='school'
            size='small'
            label={'Iskola'}
            onChange={handleChange}
            sx={{ width: '25ch' }}
          />
        </Box>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={formData.parentLastName}
            name='parentLastName'
            size='small'
            label={'Szülő vezetéknév'}
            onChange={handleChange}
          />
          <TextField
            value={formData.parentFirstName}
            name='parentFirstName'
            size='small'
            label={'Szülő keresztnév'}
            onChange={handleChange}
          />
          <TextField
            value={formData.phoneParent}
            name='phoneParent'
            size='small'
            label={'Szülő telefonszám'}
            onChange={handleChange}
          />
        </Box>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={formData.payment_method}
            name='payment_method'
            size='small'
            label={'Fizetési mód'}
            onChange={handleChange}
          />
          <TextField
            value={formData.payment_type}
            name='payment_type'
            size='small'
            label={'Fizetés gyakorisága'}
            onChange={handleChange}
          />
          {formData.taxInvoice == 1 && (
            <Chip label='Adószámos számla igény' variant='outlined' />
          )}
        </Box>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={formData.invoiceZip}
            name='invoiceZip'
            size='small'
            label={'Irányítószám'}
            onChange={handleChange}
          />
          <TextField
            value={formData.city}
            name='city'
            size='small'
            label={'Város'}
            onChange={handleChange}
          />
          <TextField
            value={formData.address}
            name='address'
            size='small'
            label={'Cím'}
            onChange={handleChange}
          />
        </Box>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={formData.birthday}
            name='birthday'
            size='small'
            label={'Születési dátum'}
            onChange={handleChange}
          />
          <Chip label={`${formData.class}. osztály`} variant='outlined' />
          {dataItem.singleLesson == 1 && (
            <Chip label={`csak egyéni óra`} variant='outlined' />
          )}
          {dataItem.groupLessons == 1 && (
            <Chip label={`csoportos órák`} variant='outlined' />
          )}
          {dataItem.lesson90 == 1 && (
            <Chip label={`90 perces órák`} variant='outlined' />
          )}
          {dataItem.lesson120 == 1 && (
            <Chip label={`120 perces órák`} variant='outlined' />
          )}
        </Box>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '90%' },
            maxWidth: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            value={dataItem.lessonDays || ''}
            name='lessonDays'
            size='small'
            label={'igényelt időszakok'}
            multiline
            variant='standard'
          />
          <TextField
            value={dataItem.subjectComment || ''}
            name='lessonDays'
            size='small'
            label={'Megjegyzés'}
            multiline
            variant='standard'
          />
          <TextField
            value={dataItem.subjects || ''}
            name='lessonDays'
            size='small'
            label={'Tantárgyak'}
            multiline
            variant='standard'
          />
        </Box>
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' color='secondary'>
            Ellenőrzés
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => createUserFromRegistration(formData)}
          >
            Betöltés
          </Button>
        </Stack>
      </div>
    </React.Fragment>
  );
}
