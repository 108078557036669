import axios from 'axios';
import { backendHOST } from '../client-config/client-config';
import setAuthToken from '../utils/setAuthToken';

export async function getHomeworks(searchParam) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        backendHOST + '/api/homework/StudenHomeworks',
        {
          params: {
            filterConditions: JSON.stringify(searchParam),
          },
        },
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        let tmpRes = [];
        res.data.map((item) => {
          tmpRes.push({
            id: item.id,
            jelenlet_id: item.jelenlet_id,
            created_at: item.created_at,
            created_by: item.created_by,
            id_diak: item.id_diak,
            homework_title: item.homework_title,
            homework_description: item.homework_description,
            homework_status: item.homework_status,
            homework_deadline: item.homework_deadline,
            diak: item.diak.text2,
            replies: item.replies.map((reply) => ({
              id: reply.id,
              file: [
                {
                  source: reply.reply_file,
                  options: {
                    type: 'local',
                  },
                },
              ],
            })),
            file: [
              {
                source: item.homework_file,
                options: {
                  type: 'local',
                },
              },
            ],
          });
        });
        return {
          isOk: true,
          data: tmpRes,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
