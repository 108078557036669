import { Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
  });

  const { username, firstName, lastName, email, password, password2 } =
    formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register({ username, firstName, lastName, email, password });
    }
  };

  // redirect is user already logged in
  if (isAuthenticated) {
    return <Navigate to='/dashboard'></Navigate>;
  }
  return (
    <Fragment>
      <h1 className='large text-primary'>Regisztráció</h1>
      <p className='lead'>
        <i className='fas fa-user'></i> Hozz létre egy felhasználói fiókot
      </p>
      <form className='form' onSubmit={(e) => onSubmit(e)}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Felhasználó név'
            name='username'
            value={username}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className='form-group-col2'>
          <input
            type='text'
            placeholder='Vezetéknév'
            name='lastName'
            value={lastName}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className='form-row'>
          <div className='form-group-col2'>
            <input
              type='text'
              placeholder='Keresztnév'
              name='firstName'
              value={firstName}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
        </div>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email cím'
            name='email'
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
          <small className='form-text'>
            This site uses Gravatar so if you want a profile image, use a
            Gravatar email
          </small>
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Jelszó'
            name='password'
            minLength='6'
            value={password}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Jelszó ismét'
            name='password2'
            minLength='6'
            value={password2}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Register' />
      </form>
      <p className='my-1'>
        Már van fiókod? <Link to='/login'>Lépj be</Link>
      </p>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
