import { Fragment, useEffect, useState } from 'react';

import CachedIcon from '@mui/icons-material/Cached';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { locale } from 'devextreme/localization';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';
import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import { getActiveDiakok } from '../../../api/masterdataAPI.';
import { backendHOST } from '../../../client-config/client-config';
import './NewHomework.scss';
//import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
registerPlugin(
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile,
  FilePondPluginImagePreview
);

//registerPlugin(FilePondPluginFileEncode);

const url = backendHOST + '/api/upload/homework';

export default function NewHomework() {
  locale(navigator.language);
  const [files, setFiles] = useState([]);
  const [descr, setDescr] = useState(
    'Kérlek oldd meg a mellékelt feladatot a következő óráig!'
  );
  const [targy, setTitle] = useState('Házi feladat!');
  const [selectedDiak, setSelectedDiak] = useState(null);
  const [diakok, setDiakok] = useState([]);

  const onNewTextChanged = (e) => {
    setTitle(e.target.value);
  };
  const onNewDescrChanged = (e) => {
    setDescr(e.target.value);
  };

  const refreshHomework = () => {
    setFiles([]);
    setSelectedDiak(null);
    setTitle('Házi feladat!');
    setDescr(
      'A mellékelt feladatok megoldásait töltsd fel vagy küld el nekünk a következő óráig! Köszönjük!'
    );
  };

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getActiveDiakok();
      if (apiResponse) {
        setDiakok(apiResponse);
      }
    }
    apiCall();
  }, []);

  const serverSettings = {
    url: url,
    process: {
      headers: {
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      },
      onload: (response) => response.key,
      onerror: (error) => error.message,
      ondata: (formData) => {
        // Add additional data to the form data
        formData.append('homeWorkTitle', targy);
        formData.append('homeWorkDescr', descr);

        formData.append('diak_id', selectedDiak.id);
        formData.append('createad_at', new Date());
        return formData;
      },
    },
  };

  return (
    <Fragment>
      <div className='dx-viewport'>
        <div className='hw-flex'>
          <div className='new-homework-title'>
            <h4>Új házi feladat rögzítése</h4>
          </div>
          <div className='hw-refresh'>
            <IconButton size='large' onClick={refreshHomework}>
              <CachedIcon />
            </IconButton>
          </div>
        </div>

        <div>
          <Autocomplete
            id='diak-selector'
            options={Array.isArray(diakok) ? diakok : []}
            clearOnEscape
            onChange={(event, newValue) => {
              setSelectedDiak(newValue);
            }}
            getOptionLabel={(option) => option.text2}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Diák kiválasztása'
                variant='standard'
              />
            )}
            value={selectedDiak}
          />
        </div>
        <div className='hw-new-text'>
          <input
            className='hw-new-text'
            type='text'
            onChange={onNewTextChanged}
            value={targy}
          ></input>
        </div>
        <div className='hw-new-text'>
          <textarea
            className='hw-new-text'
            value={descr}
            onChange={onNewDescrChanged}
          />
        </div>
        <div>{selectedDiak ? `Házi feladat: ${selectedDiak.text}` : ''}</div>
        <div className='dx-viewport'>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={true}
            allowDownloadByUrl={false}
            allowImagePreview={true}
            filePosterHeight={160}
            disabled={selectedDiak === null ? true : false}
            styleButtonRemoveItemPosition='right'
            maxFiles={5}
            server={serverSettings}
            name='files' /* sets the file input name, it's filepond by default */
            labelIdle='Húzd ide a fileokat vagy <span class="filepond--label-action">válaszd ki</span> a feltöltéshez!'
          />
        </div>
      </div>
    </Fragment>
  );
}
