import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import createLogEntry from '../../utils/createLogEntry';

const LoggedRoute = (props) => {
  const location = useLocation();

  if (props.isAuthenticated) {
    if (props.auth && props.auth.user) {
      var logEntry = {
        userId: props.auth.user.userId,
        id_user: props.auth.user.ext_id_user,
        access_object: 'SITE_USAGE',
        request: location.pathname,
      };
      createLogEntry(logEntry);
    }
  }
  return <div>{props.children}</div>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(LoggedRoute);
