import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';

import { backendHOST } from '../../../../client-config/client-config';

import CheckBox from 'devextreme-react/check-box';
import { useState } from 'react';
import { logout } from '../../../../actions/auth';
import CapacityStat from '../../../dashboard/CapacityStat';
import { withRouter } from '../../../hooks/withRouter';

const url = backendHOST + '/api/scriptantiaAdmin';

function payment(data) {
  alert('HAMAROSAN');
}

const FormLatogatottsag = ({ history, logout }) => {
  const [compareYears, switchCompareYears] = useState(false);

  function onComapreYearsChanged(e) {
    switchCompareYears(e.value);
  }

  const dataSource = createStore({
    key: 'userId',
    loadUrl: `${url}/DiakDijak`,
    loadMode: 'raw',
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    // onAjaxError: onDataErrorOccurred,
  });

  return (
    <div>
      <div>
        <CheckBox
          defaultValue={compareYears}
          text='Év összehasonlítás'
          onValueChanged={onComapreYearsChanged}
        />
      </div>
      {compareYears ? (
        <div className='w3-col  '>
          <div className='dx-row'>
            <CapacityStat pTanev='2024/2025' oktatoSelect={false} />
          </div>
          <div className='dx-row'>
            <CapacityStat pTanev='2023/2024' oktatoSelect={false} />
          </div>
        </div>
      ) : (
        <div className='w3-col  '>
          <div className='dx-row'>
            <CapacityStat pTanev='2024/2025' oktatoSelect={true} />
          </div>
        </div>
      )}
    </div>
  );
};

FormLatogatottsag.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormLatogatottsag)
);
