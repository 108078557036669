import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { getOktato } from '../api/masterdataAPI.';

const DataContext = createContext();

function DataProvider({ children }) {
  const [oktato, setOktato] = useState(null);
  const [terem, setTerem] = useState(null);

  const fetchDataMemoized = useCallback(() => {
    getOktato().then((result) => {
      setOktato(result);
    });
  }, []);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  return (
    <DataContext.Provider value={{ oktato, terem }}>
      {children}
    </DataContext.Provider>
  );
}

const useMasterData = () => useContext(DataContext);

export { DataProvider, useMasterData };
