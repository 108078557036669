import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, {
  Column,
  Editing,
  Form as GridForm,
  HeaderFilter,
  Item as GridPopupItem,
  LoadPanel,
  Lookup as GridLookup,
  Popup as GridPopup,
  SearchPanel,
  MasterDetail,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import 'devextreme-react/html-editor';
import { locale } from 'devextreme/localization';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from 'react-redux';

import { logout } from '../../../actions/auth';
import { backendHOST } from '../../../client-config/client-config';
import { EmailTemplateTypes } from '../../../utils/enums/userTypes';
import { withRouter } from '../../hooks/withRouter';
import FormMailCampaignDetails from './FormMailCampaignDetails';
import { boolEnum } from '../../../utils/enums/basicEnums';

const url = backendHOST + '/api/mail';
const urlEnd = '/Campaign';
const dateEditorOptions = {
  type: 'date',
  displayFormat: 'yyyy-MM-dd',
  useMaskBehavior: true,
};

const FormMailCampaign = ({ history, logout, auth }) => {
  locale(navigator.language);

  const notesEditorOptions = { height: 280 };

  const dataSource = createStore({
    key: 'id',
    loadUrl: `${url}${urlEnd}`,
    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          onlyNotSent: 1,
        };
      }
    },
    // onAjaxError: onDataErrorOccurred,
  });

  const newRow = (e) => {
    e.data.active = 1;
    e.data.created_at = new Date();
  };

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>e-Mail kampányok</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr='id'
                key='id'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                // dataRowRender={SMSDataRow}
                onInitNewRow={newRow}
              >
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />

                <MasterDetail
                  enabled={true}
                  component={FormMailCampaignDetails}
                />
                <Editing
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  repaintChangesOnly
                  mode='popup'
                >
                  <GridPopup
                    title='Kampány szerkesztése'
                    showTitle={true}
                    width={800}
                    height={600}
                    //onShowing={setFieldBGs}
                  />
                  <GridForm colCount={4}>
                    <GridPopupItem dataField='campaignTitle' colSpan={3} />
                    <GridPopupItem dataField='active' colSpan={1} />

                    <GridPopupItem dataField='campaignValidTo' colSpan={2} />
                    <GridPopupItem dataField='mailTemplate' colSpan={2} />
                    <GridPopupItem dataField='mailContent' colSpan={4} />
                  </GridForm>
                </Editing>

                <Column
                  dataField='campaignTitle'
                  caption='Üzenet típus'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='campaignValidTo'
                  caption='Érvényesség vége'
                  editorType='dxDateBox'
                  editorOptions={dateEditorOptions}
                  displayFormat='yyyy.MM.dd'
                  allowEditing={true}
                  visible={true}
                ></Column>

                <Column
                  dataField='mailTemplate'
                  caption='e-Mail template'
                  allowEditing={true}
                  visible={true}
                >
                  <GridLookup
                    dataSource={EmailTemplateTypes}
                    valueExpr='key'
                    displayExpr='name'
                  ></GridLookup>
                </Column>

                <Column
                  dataField='mailContent'
                  caption='Üzenet szövege'
                  allowEditing={true}
                  visible={true}
                  editorOptions={notesEditorOptions}
                  editorType='dxHtmlEditor'
                ></Column>
                <Column
                  dataField='active'
                  caption='Kampány aktív'
                  allowEditing={true}
                  visible={true}
                >
                  <GridLookup
                    dataSource={boolEnum}
                    valueExpr='id'
                    displayExpr='text'
                  ></GridLookup>
                </Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormMailCampaign.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormMailCampaign)
);
