import {
  TIMETABLE_OPEN,
  TIMETABLE_CLOSE,
  TIMETABLE_ERROR,
} from '../actions/types';

const initialState = {
  timeTable: { status: 'closed' },
  TTloading: true,
  error: {},
};

export default function timeTable(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TIMETABLE_OPEN:
      return {
        ...state,
        timeTable: payload,
        TTloading: false,
      };
    case TIMETABLE_ERROR:
      return {
        ...state,
        error: payload,
        TTloading: false,
      };
    case TIMETABLE_CLOSE:
      return {
        ...state,
        timeTable: payload,
      };
    default:
      return state;
  }
}
