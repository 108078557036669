import {
  Column,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { Fragment } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';

import { backendHOST } from '../../../../client-config/client-config';

import { logout } from '../../../../actions/auth';
import { withRouter } from '../../../hooks/withRouter';

const url = backendHOST + '/api/scriptantiaAdmin';

const FormEredmeny = ({ history, logout }) => {
  const dataSource = createStore({
    key: 'idoszak',
    loadUrl: `${url}/Eredmeny`,
    loadMode: 'raw',
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    // onAjaxError: onDataErrorOccurred,
  });

  const numberFormat = {
    type: 'fixedPoint',
    precision: 0,
  };

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Eredménykimutatás</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                // remoteOperations={true}
                keyExpr='idoszak'
                key='idoszak'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <Export enabled={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />

                <Editing
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={false}
                  repaintChangesOnly
                  mode='row'
                ></Editing>
                <Column
                  dataField='idoszak'
                  caption='Időszak'
                  visible={true}
                ></Column>
                <Column
                  dataField='Oradijak'
                  caption='Óradíjak'
                  visible={true}
                  cssClass='result-income'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='Berek'
                  caption='Bérek'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='Koltsegek'
                  caption='Költségek'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='Eredmeny'
                  caption='Eredmény'
                  visible={true}
                  cssClass='result-result'
                  format={numberFormat}
                ></Column>

                <Column
                  dataField='befizetes_sum'
                  caption='Befizetések'
                  visible={true}
                  cssClass='result-income'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='kiadasok_sum'
                  caption='Kiadások'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='CashFlow'
                  caption='Cash - Flow'
                  visible={true}
                  cssClass='result-result'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='Kintlevoseg'
                  caption='Kintlévőség'
                  visible={true}
                  cssClass='result-missing'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='Tamogatas'
                  caption='Támogatás'
                  visible={true}
                  cssClass='result-missing'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='BefizetesKup'
                  caption='Kupon felhasználás'
                  visible={true}
                  cssClass='result-missing'
                  format={numberFormat}
                ></Column>

                <Column
                  dataField='BefizetesUtal'
                  caption='Befizetés - Utalás'
                  visible={true}
                  cssClass='result-income'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='BefizetesKp'
                  caption='Befizetés - Készpénz'
                  visible={true}
                  cssClass='result-income'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='KifizetesUtal'
                  caption='Bér kifizetés - Utalás'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='KifizetesKp'
                  caption='Bér kifizetés - Készpénz'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='KoltsegUt'
                  caption='Költségek - Utalás'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>
                <Column
                  dataField='KoltsegKp'
                  caption='Költségek - Készpénz'
                  visible={true}
                  cssClass='result-spending'
                  format={numberFormat}
                ></Column>

                <Column
                  dataField='Penztar'
                  caption='Pénztár egyenleg'
                  visible={true}
                  cssClass='result-result'
                  format={numberFormat}
                ></Column>

                <Column
                  dataField='OktatoiTartozas'
                  caption='Oktatók felé tartozás'
                  visible={true}
                  format={numberFormat}
                ></Column>
                <Summary>
                  <TotalItem column='Kintlevoseg' summaryType='sum' />
                  <TotalItem column='OktatoiTartozas' summaryType='sum' />
                  <TotalItem column='Penztar' summaryType='sum' />
                  <TotalItem column='CashFlow' summaryType='sum' />
                  <TotalItem column='Oradijak' summaryType='sum' />
                  <TotalItem column='Berek' summaryType='sum' />
                  <TotalItem column='Koltsegek' summaryType='sum' />
                  <TotalItem column='Eredmeny' summaryType='sum' />
                </Summary>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormEredmeny.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(FormEredmeny));
