import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Series,
  Tooltip,
} from 'devextreme-react/chart';
import SelectBox from 'devextreme-react/select-box';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { locale } from 'devextreme/localization';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { backendHOST } from '../../client-config/client-config';
import { withRouter } from '../hooks/withRouter';
import './CapacityStat.scss';
import { getCapacityStat } from '../../api/masterdataAPI.';
import { useMasterData } from '../../contexts/DataProvider';

const url = backendHOST + '/api/stats';
const urlEnd = '/CapacityStat';
/*
const oktato = createStore({
  key: 'id',
  loadMode: 'raw',
  loadUrl: backendHOST + `/api/scriptantiaAdmin/Teacher`,

  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
});

*/

const CapacityBoard = ({ pTanev, oktatoSelect }) => {
  locale(navigator.language);
  const [AreaType, areaTypeChanged] = useState('fullstackedarea');

  const [Tanev, tanevChanged] = useState(pTanev);

  const [OktatoFilter, oktatoChanged] = useState(-1);

  const [data, setData] = useState(null);
  const { oktato } = useMasterData();

  const fetchDataMemoized = useCallback(() => {
    getCapacityStat(Tanev, oktatoSelect).then((result) => {
      setData(result);
    });
  }, [Tanev]);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  /*
  const dataSource = createStore({
    key: 'tanev',
    loadMode: 'raw',
    loadUrl: `${url}/CapacityStat`,
    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          tanev: Tanev,
          oktato: OktatoFilter,
        };
        ajaxOptions.cache = 'true';
      }
    },
  });
*/
  const tanevSources = ['2022/2023', '2023/2024', '2024/2025'];
  const types = ['area', 'stackedarea', 'fullstackedarea'];

  function handleAreaTypeChange(e) {
    areaTypeChanged(e.value);
  }

  function handleTanevChange(e) {
    tanevChanged(e.value);
  }

  function handleOktatoChange(e) {
    oktatoChanged(e.value);
  }

  function customizeTooltip(pointInfo) {
    if ('fullstackedarea' === AreaType) {
      return {
        html: `<div>
        <div class="tooltip-header">${pointInfo.argumentText}</div>
        <div class="tooltip-body">
        
        <div class="series-name">
          <span class='top-series-name'>${
            pointInfo.points[0].seriesName
          }</span>: 
          <span class='top-series-value'>${Math.round(
            (pointInfo.points[0].value * 100) /
              (pointInfo.points[0].value +
                pointInfo.points[1].value +
                pointInfo.points[2].value +
                pointInfo.points[3].value)
          )}%</span>
        </div>
      
        <div class="series-name">
          <span class='bottom-series-name'>${
            pointInfo.points[1].seriesName
          }</span>: 
          <span class='top-series-value'>${Math.round(
            (pointInfo.points[1].value * 100) /
              (pointInfo.points[0].value +
                pointInfo.points[1].value +
                pointInfo.points[2].value +
                pointInfo.points[3].value)
          )}%</span>
        </div>
     
        <div class="series-name">
          <span class='bottom-series-name'>${
            pointInfo.points[2].seriesName
          }</span>: 
          <span class='top-series-value'>${Math.round(
            (pointInfo.points[2].value * 100) /
              (pointInfo.points[0].value +
                pointInfo.points[1].value +
                pointInfo.points[2].value +
                pointInfo.points[3].value)
          )}%</span>
        </div>
     
        <div class="series-name">
        <span class='bottom-series-name'>${
          pointInfo.points[3].seriesName
        }</span>: 
        <span class='top-series-value'>${Math.round(
          (pointInfo.points[3].value * 100) /
            (pointInfo.points[0].value +
              pointInfo.points[1].value +
              pointInfo.points[2].value +
              pointInfo.points[3].value)
        )}%</span>
        </div>
    
  
  
        </div>
        </div>`,
      };
    } else {
      return {
        html: `<div>
        <div class="tooltip-header">${pointInfo.argumentText}</div>
        <div class="tooltip-body">
        <div class="series-name">
        <span class='top-series-name'>${pointInfo.points[4].seriesName}</span>: 
        <span class='top-series-value'>${pointInfo.points[4].valueText}</span>
      </div>
        <div class="series-name">
          <span class='top-series-name'>${pointInfo.points[0].seriesName}</span>: 
          <span class='top-series-value'>${pointInfo.points[0].valueText}</span>
        </div>
      
        <div class="series-name">
          <span class='bottom-series-name'>${pointInfo.points[1].seriesName}</span>: 
          <span class='bottom-series-value'>${pointInfo.points[1].valueText}</span> 
        </div>
     
        <div class="series-name">
          <span class='bottom-series-name'>${pointInfo.points[2].seriesName}</span>: 
          <span class='bottom-series-value'>${pointInfo.points[2].valueText}</span> 
        </div>
     
        <div class="series-name">
        <span class='bottom-series-name'>${pointInfo.points[3].seriesName}</span>: 
        <span class='bottom-series-value'>${pointInfo.points[3].valueText}</span> 
        </div>
    
  
  
        </div>
        </div>`,
      };
    }
  }

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round '>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Tanóra statisztika</h6>
            </div>
            <div className='chart-options'>
              <div className='chart-option'>
                <span>Diagram típusa</span>
                <SelectBox
                  dataSource={types}
                  value={AreaType}
                  inputAttr={{ 'aria-label': 'Series Type' }}
                  onValueChanged={handleAreaTypeChange}
                />
              </div>
              <div className='chart-option'>
                <span>Tanév</span>
                <SelectBox
                  dataSource={tanevSources}
                  value={Tanev}
                  onValueChanged={handleTanevChange}
                />
              </div>
              {oktatoSelect && (
                <div className='chart-option'>
                  <span>Oktató</span>
                  <SelectBox
                    dataSource={oktato}
                    value={OktatoFilter}
                    displayExpr='text'
                    valueExpr='id'
                    onValueChanged={handleOktatoChange}
                    showClearButton={true}
                  />
                </div>
              )}
            </div>

            <div id='chart-demo'>
              <Chart
                palette='Harmony Light'
                title='Tanév látogatottság'
                dataSource={data}
              >
                <CommonSeriesSettings
                  argumentField='Munkahet'
                  type={AreaType}
                />

                <Series
                  valueField='Tanora'
                  name='Megtartott'
                  color='#79DB92'
                ></Series>
                <Series
                  valueField='Lemondott'
                  name='Lemondott'
                  color='#ECD977'
                ></Series>
                <Series
                  valueField='NemSzolt'
                  name='Nem szólt'
                  color='#EA8069'
                ></Series>
                <Series
                  valueField='Torolt'
                  name='Törölt'
                  color='#93B7F3'
                ></Series>
                <Series
                  name='Összes tervezett óra'
                  valueField='Osszesen'
                  type='line'
                  color='#6b71c3'
                  visible={'fullstackedarea' !== AreaType}
                  point={{ visible: false }}
                />
                <Margin bottom={20} />
                <ArgumentAxis valueMarginsEnabled={true} />
                <Legend
                  verticalAlignment='bottom'
                  horizontalAlignment='center'
                />
                <Tooltip
                  enabled={true}
                  shared={true}
                  customizeTooltip={customizeTooltip}
                />
                <Export enabled={true} />
              </Chart>
            </div>
            <div className='dx-viewport'></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CapacityBoard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(CapacityBoard));
