import {
  Column,
  DataGrid,
  Editing,
  FilterPanel,
  HeaderFilter,
  Lookup,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';
import { Fragment } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';

import { backendHOST } from '../../../../client-config/client-config';

import { logout } from '../../../../actions/auth';

import { PaymentMethods } from '../../../../utils/enums/userTypes';
import { withRouter } from '../../../hooks/withRouter';

const url = backendHOST + '/api/scriptantiaAdmin';
const urlEnd = '/TeacherPayments';

const FormTeacherPayments = ({ history, logout, auth }) => {
  locale(navigator.language);
  const dataSource = createStore({
    key: 'id_bizonylat',

    loadUrl: `${url}/TeacherPayments`,

    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      /*
      if (method == 'insert') {
        ajaxOptions.dataType = 'json';
        let dt = {};
        dt = ajaxOptions.data;
        dt.push({ createdBy: auth.user.ext_id_user });
        ajaxOptions.data = dt;
      }
      */
    },
    // onAjaxError: onDataErrorOccurred,
  });

  const oktato = createStore({
    key: 'userId',
    loadMode: 'raw',
    loadUrl: backendHOST + '/api/auth/User',
    loadParams: { sort: { selector: 'text', desc: false } },
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          uType: 'T',
        };
      }
    },
    // onAjaxError: onDataErrorOccurred,
  });

  const dateEditorOptions = {
    type: 'date',
    displayFormat: 'yyyy.MM.dd',
    useMaskBehavior: true,
    calendarOptions: {
      firstDayOfWeek: 1,
    },
  };

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Oktató bérek könyvelése</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr='id_bizonylat'
                key='id_bizonylat'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Editing
                  allowUpdating={false}
                  allowAdding={true}
                  allowDeleting={false}
                  repaintChangesOnly
                  mode='row'
                ></Editing>

                <Column
                  dataField='id_oktato'
                  caption='Oktató'
                  allowEditing={true}
                  visible={true}
                >
                  <Lookup
                    dataSource={oktato} // {{ store: diakok, sort: 'last_name' }}
                    valueExpr='id'
                    displayExpr='text'
                  ></Lookup>
                </Column>
                <Column
                  dataField='datum'
                  caption='Kifizetés dátuma'
                  allowEditing={true}
                  editorType='dxDateBox'
                  editorOptions={dateEditorOptions}
                  defaultSortOrder='desc'
                  displayFormat='yyyy.MM.dd'
                  useMaskBehavior={true}
                  dataType='date'
                ></Column>

                <Column
                  dataField='osszeg'
                  caption='Összeg'
                  visible={true}
                  allowEditing={true}
                ></Column>
                <Column
                  dataField='descr'
                  caption='Megjegyzés'
                  allowEditing={true}
                ></Column>
                <Column
                  dataField='fiz_mod'
                  caption='Fizetési mód'
                  allowEditing={true}
                >
                  <Lookup
                    dataSource={PaymentMethods}
                    valueExpr='key'
                    displayExpr='name'
                  ></Lookup>
                </Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormTeacherPayments.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormTeacherPayments)
);
