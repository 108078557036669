import { PARAMS_GET, PARAMS_CLEAR, PARAMS_ERROR } from '../actions/types';

const initialState = {
  parameters: [],
  error: {},
};

export default function params(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PARAMS_GET:
      return {
        ...state,
        parameters: { ...payload },
      };
    case PARAMS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case PARAMS_CLEAR:
      return {
        ...state,
        parameters: [],
      };
    default:
      return state;
  }
}
