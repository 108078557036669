import React, { useState } from 'react';
import './CalendarForm.scss';
import Lookup from 'devextreme-react/cjs/lookup';
import CheckBox from 'devextreme-react/cjs/check-box';
import { useMasterData } from '../../../contexts/DataProvider';

export default function CalendarForm(props) {
  const [onlyActive, switchActiveFilter] = useState(true);
  const [onlyOwnSchedule, switchOwnFilter] = useState(-1);
  function onActiveFilterChanged(e) {
    switchActiveFilter(e.value);
  }
  function onOwnScheduleFilterChanged(e) {
    if (e.value) {
      switchOwnFilter(e.value);
    }
  }
  const { oktato } = useMasterData();

  return (
    <React.Fragment>
      <section className='dx-container'>
        <div className='dx-row-padding'>
          <div className='dx-col m12'>
            <div className='dx-card dx-round'>
              <div className='dx-container dx-padding'>
                <h6 className='dx-opacity'>Új órarend</h6>
              </div>
              <div className='options-container'>
                <div className='option'>
                  <CheckBox
                    defaultValue={onlyActive}
                    text='Csak az aktívak megjelenítése'
                    onValueChanged={onActiveFilterChanged}
                  />
                </div>
                <div className='option'>
                  <Lookup
                    dataSource={oktato}
                    value={onlyOwnSchedule}
                    onValueChanged={onOwnScheduleFilterChanged}
                    showClearButton={true}
                    clearButtonText='Törlés'
                    label='Oktató választása'
                    valueExpr='id'
                    displayExpr='text'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
