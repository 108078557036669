import axios from 'axios';
import { backendHOST } from '../client-config/client-config';
import { AlertTypes } from '../utils/enums/basicEnums';
import setAuthToken from '../utils/setAuthToken';
import { text } from '../utils/text';
import { setAlert, setMessage } from './alert';
import {
  AUTH_ERROR,
  CLEAR_PROFILE,
  CLEAR_TASKS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  PARAMS_CLEAR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
} from './types';

//Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(backendHOST + '/api/auth');
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register =
  ({ username, firstName, lastName, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const newUser = {
      username,
      firstName,
      lastName,
      email,
      password,
    };

    const body = JSON.stringify(newUser);

    try {
      const res = await axios.post(
        backendHOST + '/api/auth/user',
        body,
        config
      );
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      dispatch(loadUser());
      dispatch(setMessage('User successfully created', 'success'));
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({ type: REGISTER_FAIL });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(backendHOST + '/api/auth', body, config);
    dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    dispatch(loadUser());
    dispatch(setAlert(text.LOGIN_SUCCESSFUL, 'success'));
  } catch (err) {
    var errors = [];
    if (err.response !== null && err.response.data !== null) {
      errors = err.response.data.errors;
    }
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({ type: LOGIN_FAIL });
  }
};

export const resetPassword = (userId, PW) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ userId, PW });

  try {
    // eslint-disable-next-line
    const res = await axios.post(
      backendHOST + '/api/auth/UserPWReset',
      body,
      config
    );

    dispatch(setMessage('Sikeres Jelszó visszaállítás', AlertTypes.SUCCESS));
  } catch (err) {
    var errors = [];
    if (err.response !== null && err.response.data !== null) {
      // eslint-disable-next-line
      errors = err.response.data.errors;
    }
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

//logout
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: CLEAR_TASKS });
  dispatch({ type: PARAMS_CLEAR });
};
