export const UserTypes = [
  {
    key: 'D',
    name: 'Diákok',
  },
  {
    key: 'T',
    name: 'Tanárok',
  },
  {
    key: 'S',
    name: 'Szülők',
  } /*
  {
    key: 'A',
    name: 'Adminisztrátorok',
  },*/,
];

export const InvoiceIssuerTypes = [
  {
    key: 'ERNO',
    name: 'LE-Egyéni',
  },
  {
    key: 'TIMI',
    name: 'LKT-Egyéni',
  },
  {
    key: 'KGER',
    name: 'KG-Egyéni',
  },
];

export const PaymentMethods = [
  {
    key: 'UT',
    name: 'Utalás',
  },
  {
    key: 'KP',
    name: 'Készpénz',
  },
  {
    key: 'TM',
    name: 'Támogatás',
  },
];

export const PaymentFrequency = [
  {
    key: 'DEF',
    name: 'Óránként',
  },
  {
    key: 'HO',
    name: 'Minden hónap vége',
  },
  {
    key: '5ORA',
    name: 'Minden 5. óra',
  },
  {
    key: '5BER',
    name: '5 óránként bérlet',
  },
  {
    key: '10BER',
    name: '10 óránként bérlet',
  },
];

export const MovementTypes = [
  {
    key: 'BEF',
    name: 'Befizetés',
  },
  {
    key: 'TAM',
    name: 'Támogatás',
  },
  {
    key: 'ORA',
    name: 'Tanóra',
  },
  {
    key: 'VES',
    name: 'Leírt veszteség',
  },
  {
    key: 'IKV',
    name: 'Tanóra kedvezmény',
  },
];

export const MessageStatusTypes = [
  {
    key: '10',
    name: 'Létrehozva',
  },
  {
    key: '20',
    name: 'Előkészítve',
  },
  {
    key: '30',
    name: 'Jóváhagyva',
  },
  {
    key: '50',
    name: 'Elküldve',
  },
  {
    key: '90',
    name: 'Hiba a küldés során',
  },
  {
    key: '91',
    name: 'Lejárt',
  },
  {
    key: '99',
    name: 'Kézzel törölt',
  },
];

export const EmailTemplateTypes = [
  {
    key: 'BALANCE_INFO',
    name: 'Egyenleginfó',
  },
  {
    key: 'SIMPLE_CONTENT',
    name: 'Szöveges tartalom',
  },
];
