import axios from 'axios';
import { backendHOST } from '../client-config/client-config';
import setAuthToken from '../utils/setAuthToken';

export async function getCapacityStat(tanevFilter, oktatoFilter) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        backendHOST + '/api/stats/CapacityStat',
        {
          params: {
            tanev: tanevFilter,
            oktato: oktatoFilter,
          },
        },
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        return res.data;
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getOktato() {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        backendHOST + '/api/scriptantiaAdmin/Teacher',
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        return res.data;
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getDiakById(idDiak) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        backendHOST + '/api/userData/user',
        {
          params: {
            id: idDiak,
          },
        },
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        return res.data;
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getActiveDiakok() {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        backendHOST + '/api/auth/User',
        {
          params: {
            onlyActive: 1,
            uType: 'D',
          },
        },
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        const diakok = res.data.map((item) => {
          return {
            id: item.id_user,
            text: item.text,
            text2: item.text2,
          };
        });
        return diakok;
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
