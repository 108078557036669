import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';

const Landing = ({ isAuthenticated }) => {
  if (false) {
    //isAuthenticated
  } else {
    return (
      <React.Fragment>
        <section className='Landing'>
          <div className='dark-overlay'>
            <div className='landing-inner'>
              <h1 className='x-large'>
                Scriptantia Learning Platform
                {` v.${process.env.REACT_APP_VERSION}`}
              </h1>
              <p className='lead'>Oktatás másképp mint a suliban</p>
              <div className='buttons'>
                <Link to='/register' className='btn btn-primary'>
                  Sign Up
                </Link>
                <Link to='/login' className='btn btn-light'>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(Landing);
