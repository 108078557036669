import TextArea from 'devextreme-react/cjs/text-area';
import TextBox from 'devextreme-react/cjs/text-box';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';
import 'filepond/dist/filepond.min.css';
import React, { useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { getHomeworks } from '../../../api/homework';
import { backendHOST } from '../../../client-config/client-config';
import HomeWorkItem from './HomeWorkItem';

registerPlugin(
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const url = backendHOST + '/api/upload/homework';

export default function CreateHomeWork({
  id,

  title,
  description,
  diak_id,
  createad_at,
  status,
  deadline,
  jelenlet_id,
}) {
  const serverSettings = {
    url: url,
    process: {
      headers: {
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      },
      onload: (response) => response.key,
      onerror: (error) => error.message,
      ondata: (formData) => {
        // Add additional data to the form data
        formData.append('homeWorkTitle', title);
        formData.append('homeWorkDescr', description);
        formData.append('jelenlet_id', jelenlet_id);
        formData.append('diak_id', diak_id);
        formData.append('createad_at', createad_at);

        return formData;
      },
    },
  };

  const [files, setFiles] = useState([]);
  const [descr, setDescr] = useState(description);
  const [targy, setTitle] = useState(title);

  const [homeWorks, setHomeWorks] = useState([]);

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getHomeworks({ id_jelenlet: jelenlet_id });
      if (apiResponse) {
        setHomeWorks(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  return (
    <React.Fragment>
      <div className='dx-row-padding homework-item homerwork-create row'>
        <div className='hw-column main'>
          <TextBox value={targy} onValueChange={setTitle} />
          <TextArea value={descr} onValueChange={setDescr} />
        </div>
        <div className='hw-column file'>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={true}
            allowDownloadByUrl={false}
            allowImagePreview={true}
            filePosterHeight={80}
            styleButtonRemoveItemPosition='right'
            maxFiles={5}
            server={serverSettings}
            name='files' /* sets the file input name, it's filepond by default */
            labelIdle='Húzd ide a fileokat vagy <span class="filepond--label-action">válaszd ki</span> a feltöltéshez!'
          />
        </div>
      </div>
      <p>Eddig kiosztott feladatok:</p>
      {homeWorks &&
        homeWorks.map((hw) => {
          return (
            <HomeWorkItem
              key={hw.id}
              id={hw.id}
              file={hw.file}
              title={hw.homework_title}
              description={hw.homework_description}
              diak_id={hw.id_diak}
              createad_at={hw.created_at}
              status={hw.homework_status}
              deadline={hw.homework_deadline}
              jelenlet_id={hw.jelenlet_id}
            />
          );
        })}
    </React.Fragment>
  );
}
