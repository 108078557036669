import { PARAMS_ERROR, PARAMS_GET } from './types';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { backendHOST } from '../client-config/client-config';

export const getSettings = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await axios.get(backendHOST + '/api/params/Params');
    let params_result = [];

    res.data.map((p) => {
      params_result[p.param_name] = p.param_value;
    });
    if (res) {
      dispatch({
        type: PARAMS_GET,
        payload: params_result,
      });
    } else {
      dispatch({
        type: PARAMS_ERROR,
        error: { msg: 'no data', status: 500 },
      });
    }
  } catch (err) {
    dispatch({
      type: PARAMS_ERROR,
      error: { msg: err, status: 500 },
    });
  }
};
