import { createStore } from 'devextreme-aspnet-data-nojquery';
import { CheckBox } from 'devextreme-react/check-box';
import { Lookup } from 'devextreme-react/lookup';
import {
  Editing,
  Resource,
  Scheduler,
  Scrolling,
  View,
} from 'devextreme-react/scheduler';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setTimeTableClose } from '../../actions/timeTable';
import { backendHOST } from '../../client-config/client-config.js';
import LoadingSpinner from '../layout/LoadingSpinner';
//import TimeTableResource from '../controls/TimeTableResource';
import { locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import AppointmentToolTip from '../controls/TimeTableToolTip';
import AppointmentToolTipRestricted from '../controls/TimeTableToolTip_restricted';

//import { onDataErrorOccurred } from '../../actions/errorHandler';
import { logout } from '../../actions/auth';
import { AttendanceTypes, DiscountValues } from '../../utils/enums/adminEnums';

const url = backendHOST + '/api/scriptantiaAdmin';
const onDataErrorOccurred = async (e) => {
  if (e.xhr.status === 401) {
    logout();
  }
};

const urlOktato = '/Teacher';
const oktato = createStore({
  key: 'id_oktato_real',
  loadMode: 'raw',
  loadUrl: `${url}${urlOktato}`,

  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
  onAjaxError: onDataErrorOccurred,
});

const TimeTable = ({
  setTimeTableClose,
  auth: { user },
  timeTable: { timeTable, TTloading },
  logout,
  params,
}) => {
  // let schObj = React.createRef();
  locale(navigator.language);
  const editModes = {
    allowAdding: false,
    allowDeleting: false,
    allowResizing: true,
    allowDragging: true,
    allowUpdating: true,
  };
  const groups = ['id_terem'];

  const capacity = createStore({
    loadMode: 'raw',
    key: 'interval_start',
    loadUrl: `${url}/timetableCapacity`,

    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          date_filter: currentDate,
        };
      }
    },
    onAjaxError: onDataErrorOccurred,
  });
  const today = new Date();

  const [currentDate, setCurrentDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [onlyActive, switchActiveFilter] = useState(true);
  const [onlyOwnSchedule, switchOwnFilter] = useState(-1);
  const [ttCapacities, loadCap] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.parameters.CAPACITY_VISIBLE == 1) {
      capacity.load().then((dt) => {
        loadCap(dt);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  function getView() {
    return 'Heti';
  }
  const handlePropertyChange = useCallback(
    (e) => {
      if (e.name === 'currentDate') {
        setCurrentDate(
          new Date(e.value.getFullYear(), e.value.getMonth(), e.value.getDate())
        );
      }
      if (e.name === 'currentDate') {
        if (params.parameters.CAPACITY_VISIBLE == 1) {
          capacity.load().then((dt) => {
            loadCap(dt);
          });
        }
      }
    },
    [currentDate]
  );

  function AppointmentInfos(model) {
    const { appointmentData } = model.data;

    return (
      <div className='showtime-preview'>
        <div>
          {appointmentData.deleted === 1 && (
            <i className='fas fa-trash-alt red'></i>
          )}
          {appointmentData.signed === 1 && (
            <i className='fas fa-file-signature green'></i>
          )}
          {user.permissions.includes('timetable.details') &&
            appointmentData.tanora_torz.tanora_descr + ' - '}
          {appointmentData.oktato.last_name} {appointmentData.oktato.first_name}
        </div>

        <div>
          {new Date(appointmentData.date_start).toLocaleTimeString('hu-HU', {
            hour: '2-digit',
            minute: '2-digit',
          })}
          {'-'}
          {new Date(appointmentData.date_to).toLocaleTimeString('hu-HU', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
        <div>{appointmentData.terem && appointmentData.terem.text}</div>
      </div>
    );
  }

  const urlEnd = '/Schedule';
  const dataSource = createStore({
    loadMode: 'raw',
    key: 'scheduleId',
    //cacheRawData: false,
    loadUrl: `${url}/Schedule`,
    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          date_filter: currentDate,
          active: onlyActive,
          ownShedule: onlyOwnSchedule,
        };
        ajaxOptions.cache = 'true';
      }
    },
    onAjaxError: onDataErrorOccurred,
  });

  const urlRooms = '/Room';
  const rooms = createStore({
    key: 'id_terem',
    loadMode: 'raw',
    loadUrl: `${url}${urlRooms}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          date_filter: currentDate,
        };
        ajaxOptions.cache = 'true';
      }
    },
    onAjaxError: onDataErrorOccurred,
  });

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <section className='dx-container'>
        <div className='container content' style={{ maxWidth: '1800px' }}>
          <div className='row'>
            <div className='col m2'>
              <Link
                className='btn btn-light my-1'
                to='/'
                onClick={(e) => {
                  if (timeTable.status == 'open') {
                    setTimeTableClose();
                  }
                }}
              >
                Visszalépés a menübe
              </Link>
            </div>
            <div className='col m10'>
              <div className='options'>
                <div className='caption'>Beállítások</div>
                <div className='options-container'>
                  <div className='option'>
                    <CheckBox
                      defaultValue={onlyActive}
                      text='Csak az aktívak megjelenítése'
                      onValueChanged={onActiveFilterChanged}
                    />
                  </div>
                  <div className='option'>
                    <Lookup
                      dataSource={oktato}
                      value={onlyOwnSchedule}
                      onValueChanged={onOwnScheduleFilterChanged}
                      showClearButton={true}
                      clearButtonText='Törlés'
                      label='Oktató választása'
                      valueExpr='id'
                      displayExpr='text'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='dx-row'>
            <div className='dx-col m12'>
              <Scheduler
                className='timetable'
                //  ref={(ref) => (schObj = ref)}
                id='scheduler'
                dataSource={dataSource}
                textExpr='tanora_torz.tanora_descr'
                startDateExpr={'date_start'}
                endDateExpr={'date_to'}
                timeCellTemplate={timeCellTemplate}
                //resourceCellComponent={TimeTableResource}

                //cacheEnabled={false}

                timeZone='Europe/Budapest'
                cellDuration={30}
                showCurrentTimeIndicator={true}
                adaptivityEnabled={true}
                recurrenceRuleExpr='recurrence'
                currentDate={currentDate}
                onOptionChanged={handlePropertyChange}
                appointmentComponent={AppointmentInfos}
                appointmentTooltipComponent={
                  user.permissions.includes('timetable.details')
                    ? AppointmentToolTip
                    : AppointmentToolTipRestricted
                }
                onAppointmentFormOpening={onAppointmentFormOpening}
                defaultCurrentView={getView}
                colorExpr='color'
                firstDayOfWeek={1}
                showAllDayPanel={false}
                editing={editModes}
                onDataErrorOccurred={onDataErrorOccurred}
                onAppointmentUpdated={showUpdateInfos}
                onAppointmentUpdating={onAppointmentUpdating}
                //   dataCellRender={renderDataCell}
                //dataCellComponent={TimeTableDataCell}
                // dateCellRender={renderDateCell}
                // timeCellRender={renderTimeCell}

                // views={views}
              >
                <Resource
                  label='Terem'
                  fieldExpr='id_terem'
                  dataSource={rooms}
                  allowMultiple={false}
                />
                <Resource
                  label='Oktato'
                  fieldExpr='id_oktato_real'
                  dataSource={oktato}
                  allowMultiple={false}
                  useColorAsDefault={true}
                />

                <View
                  name='Napi'
                  type='day'
                  intervalCount={1}
                  startDayHour={7}
                  endDayHour={22}
                  groups={groups}
                />
                {/*<View
                  name='3 Napos'
                  type='day'
                  intervalCount={3}
                  startDate={new Date()}
                  startDayHour={7}
                  endDayHour={22}
              />*/}
                <View
                  name='Heti'
                  type='week'
                  intervalCount={1}
                  startDate={new Date()}
                  startDayHour={7}
                  endDayHour={22}
                />
                {/* <View
                  name='2 hetes'
                  type='week'
                  intervalCount={2}
                  startDate={new Date()}
                  startDayHour={7}
                  endDayHour={22}
                />

                <View name='Havi' type='month' intervalCount={1} />
              */}

                <Editing allowDragging={false} />
                <Scrolling mode='virtual' />
                {/* Configuration goes here 
                
                
                */}
              </Scheduler>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );

  function onOwnScheduleFilterChanged(e) {
    if (e.value) {
      switchOwnFilter(e.value);
    }
  }
  function onAppointmentFormOpening(e) {
    const { form } = e;
    console.log(e);

    if (
      !user.permissions.includes('timetable.details') &&
      e.appointmentData.id_oktato_real !== user.ext_id_user
    ) {
      e.cancel = true;
    }

    let formItems = [
      {
        label: {
          text: 'Terem',
        },
        editorType: 'dxSelectBox',
        dataField: 'id_terem',
        editorOptions: {
          dataSource: rooms,
          displayExpr: 'text',
          valueExpr: 'id',
          /*onValueChanged(args) {
        form.updateData('director', movieInfo.director);
      },*/
        },
      },
      {
        label: {
          text: 'Oktató',
        },
        editorType: 'dxSelectBox',
        dataField: 'id_oktato_real',
        editorOptions: {
          dataSource: oktato,
          displayExpr: 'text',
          valueExpr: 'id',
        },
      },
      {
        label: {
          text: 'Dátum',
        },
        editorType: 'dxDateBox',
        editorOptions: {
          formatString: 'yyyy MM dd',
        },
        dataField: 'datum',
      },
    ];
    e.appointmentData.tanora_jelenlets.map((d, i) => {
      formItems.push(
        {
          label: {
            text: d.diak.text,
          },
          editorType: 'dxRadioGroup',
          dataField: 'jelenlet_real.' + d.jelenlet_id,
          value: d.jelenlet_id,
          editorOptions: {
            dataSource: AttendanceTypes, // Attendance, canceled, missed
            displayExpr: 'name',
            valueExpr: 'key',
            value: d.schedule_attendance,
          },
        },

        {
          label: {
            text: 'Kedvezmény',
          },
          editorType: 'dxSelectBox',
          dataField: 'discount_prc.' + d.jelenlet_id,
          value: d.jelenlet_id,
          editorOptions: {
            dataSource: DiscountValues,
            displayExpr: 'name',
            valueExpr: 'key',
            value: d.discount_prc,
          },
        },

        {
          label: {
            text: 'Óra törlése',
          },
          editorType: 'dxCheckBox',
          dataField: 'deletedx.' + d.jelenlet_id,
          value: d.deleted,
        }
      );
    });

    form.option('items', formItems);
    form.option('colCountByScreen', { lg: 3, md: 3, sm: 3 });
    e.popup.option({
      minWidth: 600,
    });
  }
  function onAppointmentUpdating(e) {
    if (
      !(
        e.oldData.id_oktato_real == user.ext_id_user ||
        user.permissions.includes('timetable.admin')
      )
    ) {
      e.cancel = true;
      showToast('Hiba', `Csak saját óra szerkeszthető`, 'info');
    }
    if (e.oldData.deleted == 1) {
      e.cancel = true;
      showToast(
        'Hiba',
        `A tanóra "${e.oldData.tanora_torz.tanora_descr}" már törölve lett`,
        'info'
      );
    }
    if (e.oldData.signed == 1) {
      e.cancel = true;
      showToast(
        'Hiba',
        `A tanóra "${e.oldData.tanora_torz.tanora_descr}" már lezárásra került`,
        'info'
      );
    }
  }

  function onActiveFilterChanged(e) {
    switchActiveFilter(e.value);
  }
  function showUpdateInfos(e) {
    showToast('Módosítva', e.appointmentData.message, 'info');
    const td = new Date();
    setCurrentDate(new Date(td.getFullYear(), td.getMonth(), td.getDate()));
  }
};

function timeCellTemplate(e) {
  return new Date(e.date).toLocaleString('hu-HU', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

function renderDataCell(itemData) {
  let megvan = false;
  /*
  if (params.parameters.CAPACITY_VISIBLE == 1) {
    if (
      ttCapacities.find(
        (d) =>
          new Date(d.interval_start).toISOString() ==
          new Date(
            Date.UTC(
              new Date(itemData.startDate).getFullYear(),
              new Date(itemData.startDate).getMonth(),
              new Date(itemData.startDate).getDate(),
              new Date(itemData.startDate).getHours(),
              new Date(itemData.startDate).getMinutes(),
              new Date(itemData.startDate).getSeconds(),
              0
            ) //- offsetToHU
          ).toISOString()
      )
    ) {
      megvan = true;
    }

    return (
      <div className={megvan ? 'szabad' : 'foglalt'}>
        <div>{'.'}</div>
      </div>
    );
  }
  */
}

function showToast(event, value, type) {
  notify(`${event} "${value}" `, type, 5000);
}

TimeTable.propTypes = {
  setTimeTableClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  timeTable: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  timeTable: state.timeTable,
  params: state.params,
});

export default connect(mapStateToProps, { setTimeTableClose, logout })(
  TimeTable
);
